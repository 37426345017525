import { QueryFunctionContext, useQueries, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { DealerReportQueryKeys } from "modules/DealerReports/queryKeys";
import { parseDealerReportData } from "modules/DealerReports/util/parser";
import { ParsedDealerReport } from "modules/DealerReports/util/types";
import ApiInstance from "util/Api";
import { translateReportForCharts } from "util/common";
import { ITranslation } from "util/interfaces";
import { IBackendQueryKey } from "util/keyFactory";

interface UseDealerReportsParams {
  dealer_id: number;
  date_from: string;
  date_to: string;
}

export const useDealerReports = ({ dealer_id, date_from, date_to }: UseDealerReportsParams) => {
  const [selectedGroup, setSelectedGroup] = useState<number | null>(null);
  const [reportsSequentialLoader, setReportsSequentialLoader] = useState<boolean[]>([]);

  const initialGroupSetRef = useRef(false);
  const lastValidDatesRef = useRef<{ date_from: string; date_to: string } | null>(null);

  const t = useTranslation().t as ITranslation;

  if (date_from && date_to) lastValidDatesRef.current = { date_from, date_to };
  const datesForQuery = lastValidDatesRef.current;

  const getDealerReportGroups = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    const { baseUrl, params } = queryKey[0];
    try {
      const response = await ApiInstance.post("/dealers/list_groups", { ...params }, baseUrl);
      setSelectedGroup(response.data.groups[0]);
      return response.data.groups as number[];
    } catch (err) {
      toast.error(t("failed_to_load_report_groups").message || "Failed to load report groups");
      throw err;
    }
  };

  const getDealerReportList = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    const { baseUrl, params } = queryKey[0];
    try {
      const response = await ApiInstance.post("/dealers/list", { ...params }, baseUrl);
      const template_ids = response.data.template_ids || [];
      setReportsSequentialLoader(template_ids.map((_: number, index: number) => index === 0));
      return template_ids as number[];
    } catch (err) {
      toast.error(t("failed_to_load_report").message || "Failed to load report");
      throw err;
    }
  };

  const getDealerReport = async ({ signal, queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>, index: number) => {
    const { baseUrl, params } = queryKey[0];
    try {
      const response = await ApiInstance.post("/dealers/view", params, baseUrl, { signal });
      const report = response.data;
      const parsedReportData = report.report_data ? report.report_data.map((d: string) => JSON.parse(d)) : [];
      const parsedReportThreshold = report.thresholds ? JSON.parse(report.thresholds) : [];

      const processedData = report.report_type === "SimpleBarChart" ? parsedReportData.map((d: string[]) => translateReportForCharts(d, t)) : parsedReportData;

      const parsedDealerReportData = parseDealerReportData({ ...report, report_data: processedData });

      if (index < reportsSequentialLoader.length - 1) setReportsSequentialLoader(prev => prev.with(index + 1, true));

      return { ...response.data, ...parsedDealerReportData, thresholds: parsedReportThreshold } as ParsedDealerReport;
    } catch (err) {
      if (index < reportsSequentialLoader.length - 1) setReportsSequentialLoader(prev => prev.with(index + 1, true));
      toast.error(t("failed_to_load_report").message || "Failed to load report");
      throw err;
    }
  };

  const processEmailStats = (emailStatsData: Record<string, any>[]): Record<string, number>[] => {
    if (!emailStatsData?.length) return [];

    const totals = emailStatsData.reduce(
      (acc, item) => ({
        accepted: acc.accepted + item.accepted.total,
        delivered: acc.delivered + item.delivered.total,
        failed: acc.failed + item.failed.permanent.total,
        stored: acc.stored + item.stored.total,
        complained: acc.complained + item.complained.total
      }),
      {
        accepted: 0,
        delivered: 0,
        failed: 0,
        stored: 0,
        complained: 0
      }
    );

    return [
      { [t("v8_accepted").message || "Accepted"]: totals.accepted },
      { [t("v8_delivered").message || "Delivered"]: totals.delivered },
      { [t("v8_failed").message || "Failed"]: totals.failed },
      { [t("v8_stored").message || "Stored"]: totals.stored },
      { [t("v8_complained").message || "Complained"]: totals.complained }
    ];
  };

  const getEmailStats = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    const { baseUrl, params, endpoint } = queryKey[0];
    try {
      const response = await ApiInstance.post(endpoint, params, baseUrl);
      return processEmailStats(response.data?.emails);
    } catch (err) {
      toast.error(t("failed_to_load_report").message || "Failed to load report");
      throw err;
    }
  };

  const groups = useQuery({
    queryKey: DealerReportQueryKeys.list_groups(dealer_id),
    queryFn: getDealerReportGroups,
    enabled: !!dealer_id,
    staleTime: Infinity
  });

  useEffect(() => {
    if (groups.data && groups.data.length > 0 && !selectedGroup && !initialGroupSetRef.current) {
      initialGroupSetRef.current = true;
      setSelectedGroup(groups.data[0]);
    }
  }, [groups.data, selectedGroup]);

  const reportList = useQuery({
    queryKey: DealerReportQueryKeys.list_reports(dealer_id, Number(selectedGroup)),
    queryFn: getDealerReportList,
    enabled: !!selectedGroup && !!dealer_id,
    staleTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  const period = datesForQuery ? moment(datesForQuery.date_to).diff(moment(datesForQuery.date_from), "days").toString() : null;

  const emailStats = useQuery({
    queryKey: DealerReportQueryKeys.email_stats(dealer_id, period),
    queryFn: getEmailStats,
    enabled: selectedGroup === 3 && !!dealer_id && !!period,
    staleTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  useEffect(() => {
    if (reportList.isSuccess && reportList.data && reportList.data?.length > 0 && reportsSequentialLoader.length === 0) {
      setReportsSequentialLoader(reportList.data.map(() => false));
    }
  }, [reportList.isSuccess, reportList.data, reportsSequentialLoader.length]);

  const reports = useQueries({
    queries:
      reportList.data?.map((report_id: number, index: number) => ({
        queryKey: DealerReportQueryKeys.dealer_report(index, dealer_id, report_id, datesForQuery?.date_from, datesForQuery?.date_to),
        queryFn: (queryFnParams: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => getDealerReport(queryFnParams, index),
        enabled: !!report_id && !!datesForQuery && (index === 0 || (index > 0 && reportsSequentialLoader[index - 1]))
      })) ?? []
  });

  return {
    groups,
    selectedGroup,
    setSelectedGroup,
    reportList,
    reports,
    emailStats,
    loading: groups.isLoading || reportList.isLoading,
    error: groups.isError || reportList.isError
  };
};
