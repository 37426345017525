import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { useSortable } from "@dnd-kit/sortable";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Icon, Image, Label, Popup } from "semantic-ui-react";

import { AppointmentStatusCell, useCan } from "components";
import "modules/Dayplanner/components/AppointmentCard/AppointmentCard.scss";
import { CarQuickView, InterventionQuickView, NotificationIcons } from "modules/Dayplanner/components/AppointmentCard/components";
import { DAYPLANNER_COLUMNS, DayplannerAppointment, MechanicWithAppointments } from "modules/Dayplanner/interfaces";
import { PATHS } from "router/paths";
import { classNames, getInitials } from "util/common";
import { ITranslation } from "util/interfaces";

type AppointmentCardProps = {
  appointment: DayplannerAppointment;
  dndAttributes?: DraggableAttributes;
  dndListeners?: SyntheticListenerMap;
  column: DAYPLANNER_COLUMNS;
  ref?: React.Ref<HTMLElement>;
  mechanic?: MechanicWithAppointments;
  isColumnCollapsed?: boolean;
};

type SortableAppointmentCardProps = {
  appointment: DayplannerAppointment;
  mechanic: MechanicWithAppointments;
  column: DAYPLANNER_COLUMNS;
  isColumnCollapsed?: boolean;
  onOver: (mechanicId: number) => void;
};

type DroppableAppointmentCardProps = {
  appointment: DayplannerAppointment;
  column: DAYPLANNER_COLUMNS;
  isColumnCollapsed?: boolean;
};

type AppointmentContentProps = {
  appointment: DayplannerAppointment;
  column: DAYPLANNER_COLUMNS;
  dndAttributes: DraggableAttributes;
  dndListeners: SyntheticListenerMap;
  mechanic?: MechanicWithAppointments;
};

type MechanicInfoProps = {
  appointment: DayplannerAppointment;
  mechanic?: MechanicWithAppointments;
  column: DAYPLANNER_COLUMNS;
};

const MechanicInfo = ({ appointment, mechanic, column }: MechanicInfoProps) => {
  const t = useTranslation().t as ITranslation;

  if (!mechanic) return null;

  const content = (
    <div>
      <span>
        {mechanic.first_name} {mechanic.last_name}
      </span>
      {column !== DAYPLANNER_COLUMNS.CarReady && (
        <>
          <div>{`${t("start_time").message || "Start time"}: ${moment(appointment.planning_work_start).format("DD-MM-YYYY HH:mm")}`}</div>
          <div>{`${t("end_time").message || "End time"}: ${moment(appointment.planning_work_stop).format("DD-MM-YYYY HH:mm")}`}</div>
        </>
      )}
    </div>
  );

  const trigger = mechanic.profile_picture ? (
    <Image src={mechanic.profile_picture} avatar />
  ) : (
    <div className="initials-fallback">{getInitials(mechanic.first_name, mechanic.last_name)}</div>
  );

  return <Popup content={content} trigger={trigger} position="top right" />;
};

const AppointmentContent = ({ appointment, mechanic, column, dndAttributes, dndListeners }: AppointmentContentProps) => {
  const history = useHistory();
  const planningWorkStop = appointment.car_return_time || appointment.planning_work_stop;
  const boardItemClassNames = classNames({
    "-bg-color-red": !!(planningWorkStop && moment(planningWorkStop).diff(moment(), "minutes") <= 0),
    "-bg-color-yellow": !!(planningWorkStop && moment(planningWorkStop).diff(moment(), "minutes") <= 60 && moment(planningWorkStop).diff(moment(), "minutes") > 0),
    "-appointment-internal": appointment.internal,
    "-panic": appointment.has_panic,
    "-inactive": column === DAYPLANNER_COLUMNS.Mechanics && appointment.inactive
  });
  const totalLaborMinutes = appointment.interventions?.reduce((acc, curr) => acc + (curr.labor_minutes || 0), 0);

  const onAppointmentClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    history.push(`${PATHS.APPOINTMENTS}/${appointment.id}`);
  };

  const handleCarDetailLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`${PATHS.CAR_DETAIL}/${appointment.car_id}`);
  };

  const handleAppointmentDetailsLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`${PATHS.APPOINTMENTS}/${appointment.id}`);
  };

  return (
    <div className={`BoardItem ${boardItemClassNames}`} onClick={onAppointmentClick}>
      <div className="BoardItem-appointment-status -expanded">
        <AppointmentStatusCell
          dndListeners={dndListeners}
          dndAttributes={dndAttributes}
          value={{
            id: appointment.appointment_status_identifier,
            car_out_of_shop: appointment.car_out_of_shop,
            customcom_status: appointment.customer_communication?.status,
            check_paused_at: appointment.check_paused_at
          }}
        />

        {appointment.kiosk_label_number && (
          <Label className="BoardItem-kiosk-label">
            <Icon className="tag" />
            <span>{appointment.kiosk_label_number}</span>
          </Label>
        )}
      </div>

      <div className="BoardItem-info -cursor-move">
        <CarQuickView
          appointment={appointment}
          popupTrigger={
            <span className="-cursor-pointer" onClick={handleCarDetailLink}>
              {appointment.reg_number ? `${appointment.reg_number}` : ""}
            </span>
          }
        />

        {appointment.reg_number ? " - " : ""}

        <InterventionQuickView
          appointment={appointment}
          popupTrigger={
            <span className="-cursor-pointer wo-nr" onClick={handleAppointmentDetailsLink}>
              {appointment.wo_nr}
            </span>
          }
        />
      </div>

      <div className="BoardItem-last-update">
        {planningWorkStop && (
          <span>
            {moment(planningWorkStop).format("DD-MM HH:mm")}

            {totalLaborMinutes ? (
              <span className="labor-minutes">
                <Icon className="clock" />
                {moment.duration(totalLaborMinutes, "minutes").format("HH:mm", { trim: false })}
              </span>
            ) : null}
          </span>
        )}
      </div>

      <div className="BoardItem-bottom-elements">
        <NotificationIcons appointment={appointment} />

        {[DAYPLANNER_COLUMNS.Unassigned, DAYPLANNER_COLUMNS.CarReady].includes(column) && (
          <div className="BoardItem-planning-mechanic">
            <MechanicInfo appointment={appointment} mechanic={mechanic} column={column} />
          </div>
        )}
      </div>
    </div>
  );
};

const CollapsedAppointmentContent = ({ appointment, mechanic, column, dndAttributes, dndListeners }: AppointmentContentProps) => {
  const trigger = (
    <Link className="BoardItem-appointment-status -collapsed" to={`/appointments/${appointment.id}`} {...dndAttributes} {...dndListeners}>
      <AppointmentStatusCell
        value={{
          id: appointment.appointment_status_identifier,
          car_out_of_shop: appointment.car_out_of_shop,
          customcom_status: appointment.customer_communication?.status,
          check_paused_at: appointment.check_paused_at
        }}
      />
    </Link>
  );

  const content = (
    <div className="BoardItem-appointment-status">
      <AppointmentContent appointment={appointment} mechanic={mechanic} column={column} dndAttributes={dndAttributes} dndListeners={dndListeners} />
    </div>
  );

  return <Popup basic hoverable size="large" trigger={trigger} content={content} style={{ padding: "0", boxShadow: "none", border: "none" }} />;
};

export const AppointmentCard = ({
  appointment,
  mechanic,
  column,
  isColumnCollapsed = false,
  dndAttributes = { role: "", tabIndex: 0, "aria-disabled": false, "aria-pressed": undefined, "aria-roledescription": "", "aria-describedby": "" },
  dndListeners = {}
}: AppointmentCardProps) => {
  const isMechanicColumn = column === DAYPLANNER_COLUMNS.Mechanics;

  if (appointment.inactive && !isMechanicColumn) return null;

  return (
    <div className={`AppointmentCard ${isMechanicColumn ? "-mechanic" : ""}`}>
      {isColumnCollapsed ? (
        <CollapsedAppointmentContent appointment={appointment} mechanic={mechanic} column={column} dndAttributes={dndAttributes} dndListeners={dndListeners} />
      ) : (
        <AppointmentContent appointment={appointment} mechanic={mechanic} column={column} dndAttributes={dndAttributes} dndListeners={dndListeners} />
      )}
    </div>
  );
};

export const MechanicAppointmentCard = ({ appointment, mechanic, onOver, ...rest }: SortableAppointmentCardProps) => {
  const canAssignWo = useCan("assign", "dayplanner");
  const { attributes, isDragging, listeners, setNodeRef, transform, transition, isOver } = useSortable({
    id: appointment.id,
    disabled: !canAssignWo,
    data: {
      appointment: { id: appointment.id, assigned_mechanic: appointment.assigned_mechanic, assigned_mechanic_order: appointment.assigned_mechanic_order },
      mechanicId: mechanic.id,
      order: appointment.assigned_mechanic_order
    }
  });

  useEffect(() => {
    if (isOver) onOver(mechanic.id);
  }, [isOver]);

  const style = {
    transition,
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : "none",
    opacity: isDragging ? 0.3 : 1
  };

  return (
    <div style={style} ref={setNodeRef}>
      <AppointmentCard dndListeners={listeners} dndAttributes={attributes} appointment={appointment} mechanic={mechanic} {...rest} />
    </div>
  );
};

export const UnassignedAppointmentCard = ({ appointment, ...rest }: DroppableAppointmentCardProps) => {
  const canAssignWo = useCan("assign", "dayplanner");
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: appointment.id,
    disabled: !canAssignWo,
    data: { appointment: { id: appointment.id }, mechanicId: null, unassigned: true }
  });

  const style = { transition, transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : "none", opacity: isDragging ? 0.3 : 1 };

  return (
    <div style={style} ref={setNodeRef}>
      <AppointmentCard dndListeners={listeners} dndAttributes={attributes} appointment={appointment} {...rest} />
    </div>
  );
};
