import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import React from "react";
import { Icon } from "semantic-ui-react";

import { ReactComponent as FaceIcon } from "assets/svg/face.svg";
import "components/AppointmentStatusCell/AppointmentStatusCell.scss";
import { useDealersLocations } from "hooks";
import { AppointmentStatus, COMMUNICATION_STATUS, STATUS_IDENTIFIER } from "models";

type AppointmentStatusValue = {
  id: STATUS_IDENTIFIER;
  customcom_status?: COMMUNICATION_STATUS | null;
  check_paused_at?: string | null;
  car_out_of_shop?: string | null;
  car_in_shop?: string | null;
};

interface StatusCellProps {
  value: AppointmentStatusValue;
  shadow?: boolean;
  dndAttributes?: DraggableAttributes;
  dndListeners?: SyntheticListenerMap;
}

type StatusIconProps = {
  value?: AppointmentStatusValue;
  status: STATUS_IDENTIFIER;
  color?: string;
  className?: string;
};

const carInShopStatus = (appointment?: AppointmentStatusValue) => appointment?.car_in_shop && !appointment.car_out_of_shop;

export const getAppointmentStatus = (appointmentStatus: STATUS_IDENTIFIER | undefined, locationStatuses: AppointmentStatus[] | undefined): AppointmentStatus => {
  const defaultStatus: AppointmentStatus = {
    id: 0,
    identifier: 1,
    order: 0,
    dealer_location_id: 0,
    created_on: "",
    updated_on: "",
    icon: "settings",
    color: "",
    name: ""
  };

  if (!locationStatuses) return defaultStatus;

  return locationStatuses.find(s => s.identifier === appointmentStatus) || defaultStatus;
};

export const StatusIcon = ({ status, value, color, className = "" }: StatusIconProps) => {
  const iconProps = {
    style: color ? { color } : undefined
  };

  switch (status) {
    case STATUS_IDENTIFIER.CanceledStatus:
      return <Icon className={`circle xmark ${className}`} {...iconProps} />;

    case STATUS_IDENTIFIER.CarOutOfShop:
      return <Icon className={`car mirrors ${className}`} {...iconProps} />;

    case STATUS_IDENTIFIER.NewCarStatus:
      return <Icon className={carInShopStatus(value) ? `car garage ${className}` : `car mirrors ${className}`} {...iconProps} />;

    case STATUS_IDENTIFIER.CarCheckStatus:
      return <Icon className={`wrench ${className}`} {...iconProps} />;

    case STATUS_IDENTIFIER.PricingOKStatus:
      return <Icon className={`tag ${className}`} {...iconProps} />;

    case STATUS_IDENTIFIER.CustomerOKStatus:
      return (
        <div className={`svg-face-icon ${className}`}>
          <FaceIcon style={{ fill: color }} />
        </div>
      );

    case STATUS_IDENTIFIER.CarReadyStatus:
      return <Icon className={`check double ${className}`} {...iconProps} />;

    case STATUS_IDENTIFIER.DiagnoseStatus:
      return <Icon className={`triangle exclamation ${className}`} {...iconProps} />;

    case STATUS_IDENTIFIER.AannameOKStatus:
      return <Icon className={`square a ${className}`} {...iconProps} />;

    case STATUS_IDENTIFIER.CalculatingStatus:
      return <Icon className={`dollar sign ${className}`} {...iconProps} />;

    case STATUS_IDENTIFIER.ContactStatus:
      if (Number(value?.customcom_status) >= COMMUNICATION_STATUS.DiagnoseOverviewSent) {
        return <Icon className={`mail ${className}`} {...iconProps} />;
      }
      return <Icon className={`phone volume ${className}`} {...iconProps} />;

    case STATUS_IDENTIFIER.CarCheckStartedStatus:
      if (value?.check_paused_at) {
        return <Icon className={`circle pause ${className}`} {...iconProps} />;
      }

      return <Icon className={`circle quarter stroke ${className}`} {...iconProps} />;

    case STATUS_IDENTIFIER.QualityCheckStatus:
      return <Icon className={`shield check ${className}`} {...iconProps} />;

    case STATUS_IDENTIFIER.CustomerAnsweredStatus:
      return <Icon className={`user check ${className}`} {...iconProps} />;

    case STATUS_IDENTIFIER.BackOrderStatus:
      return (
        <div className={`bo-icon ${className}`} {...iconProps}>
          BO
        </div>
      );

    case STATUS_IDENTIFIER.CheckInCar:
      return <Icon className={`calendar check ${className}`} {...iconProps} />;

    case STATUS_IDENTIFIER.CheckInDone:
      return (
        <span>
          <Icon className="car mirrors" />
          <Icon className="calendar check top-right" />
        </span>
      );

    case STATUS_IDENTIFIER.CarInShop:
      return <Icon className={`car garage ${className}`} {...iconProps} />;

    case STATUS_IDENTIFIER.CarOkPlusRepairOverview:
      return (
        <span>
          <Icon className="check double" />
          <Icon className="envelope top-right" />
        </span>
      );

    case STATUS_IDENTIFIER.QualityCheckPlusRepairOverview:
      return (
        <span>
          <Icon className="shield check" />
          <Icon className="envelope top-right" />
        </span>
      );

    default:
      return null;
  }
};

export const AppointmentStatusCell = ({
  value,
  shadow = true,
  dndAttributes = { role: "", tabIndex: 0, "aria-disabled": false, "aria-pressed": undefined, "aria-roledescription": "", "aria-describedby": "" },
  dndListeners = {}
}: StatusCellProps) => {
  const { selectedLocation } = useDealersLocations();
  const status = getAppointmentStatus(value.id, selectedLocation?.statuses);

  const commonStyle: React.CSSProperties = {
    backgroundColor: status.color,
    boxShadow: shadow ? "-1px 1px 3px #00000029" : "none"
  };

  return (
    <div className="StatusCellIcon" style={commonStyle} title={status.name} {...dndAttributes} {...dndListeners}>
      {value.car_out_of_shop && <Icon className="right from bracket small-icon" />}
      <StatusIcon status={status.identifier} value={value} />
    </div>
  );
};
