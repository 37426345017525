import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Icon } from "semantic-ui-react";

import { CUSTOM_CONFIRM_TYPES, Can, CustomConfirm } from "components";
import { Car, CarTyres, STATUS_IDENTIFIER } from "models";
import "modules/CarDetails/components/Tyres/Tyres.scss";
import { Appointment, useDeleteTyres } from "modules/CarDetails/hooks";
import apiInstance from "util/Api";
import { getTyreSeasonIcon } from "util/common";
import { ITranslation } from "util/interfaces";

type TyresProps = {
  data: Car | null;
  lastAppointment?: Appointment;
};
type CarTyreInfo = CarTyres | any;

export const Tyres = ({ data, lastAppointment }: TyresProps) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const onCarTyres: CarTyreInfo = data?.on_car_tyres;
  const inStorageTyres: CarTyreInfo = data?.in_storage_tyres;
  const { mutate: deleteTyreMutation, isPending: isDeleting } = useDeleteTyres();
  const t = useTranslation().t as ITranslation;

  const isDeleteButtonDisabled = lastAppointment?.appointment_status_identifier === STATUS_IDENTIFIER.CarCheckStartedStatus;

  async function handleTyreDelete() {
    if (!data?.has_dbb) return setIsOpenDeleteModal(true);
    try {
      const res = await apiInstance.post("/appointments/portal_dbb", { id: lastAppointment?.id });
      window.open(res.data, "_blank");
    } catch (err) {
      toast.error(t("v8_error").message || "Something went really wrong ");
      throw err;
    }
  }
  return (
    <>
      <div>
        {onCarTyres && (
          <div className="Tyres">
            <h4>{t("v8_on_car").message || "ON CAR"}</h4>
            <div className="flex-row">
              <div className="tyre-container">
                <div className="header">
                  <h4>{t("v8_front_tyres").message || "Front tyres"}</h4>
                </div>
                <div className="flex-row padding">
                  <div>
                    <div className="flex-row small-gap start tyre-details-font">
                      <div className="tyre-indicator">
                        <p>L</p>
                      </div>
                      <p>{`${onCarTyres?.fl_profile} mm`}</p>
                    </div>
                    <div className="tyre-details tyre-details-font">
                      <p>
                        {onCarTyres.front_left_tyre.manufacturer}
                        <span>
                          <Icon className={getTyreSeasonIcon(onCarTyres.front_left_tyre.season)} />
                        </span>
                      </p>
                      <p>{onCarTyres.front_left_tyre.description}</p>
                    </div>
                  </div>
                  <div>
                    <div className="flex-row small-gap end tyre-details-font">
                      <p>{`${onCarTyres?.fr_profile} mm`}</p>

                      <div className="tyre-indicator">
                        <p>R</p>
                      </div>
                    </div>
                    <div className="tyre-details tyre-details-font right">
                      <p>
                        <span>
                          <Icon className={getTyreSeasonIcon(onCarTyres.front_right_tyre.season)} />
                        </span>
                        {onCarTyres.front_right_tyre.manufacturer}
                      </p>
                      <p>{onCarTyres.front_right_tyre.description}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tyre-container">
                <div className="header">
                  <h4>{t("v8_rear_tyres").message || "Rear tyres"}</h4>
                </div>
                <div className="flex-row padding">
                  <div>
                    <div className="flex-row small-gap start tyre-details-font">
                      <div className="tyre-indicator">
                        <p>L</p>
                      </div>
                      <p>{`${onCarTyres?.rl_profile} mm`}</p>
                    </div>
                    <div className="tyre-details tyre-details-font">
                      <p>
                        {onCarTyres.rear_left_tyre.manufacturer}
                        <span>
                          <Icon className={getTyreSeasonIcon(onCarTyres.rear_left_tyre.season)} />
                        </span>
                      </p>
                      <p>{onCarTyres.rear_left_tyre.description}</p>
                    </div>
                  </div>
                  <div>
                    <div className="flex-row small-gap end tyre-details-font">
                      <p>{`${onCarTyres?.rr_profile} mm`}</p>
                      <div className="tyre-indicator">
                        <p>R</p>
                      </div>
                    </div>
                    <div className="tyre-details tyre-details-font right">
                      <p>
                        <span>
                          <Icon className={getTyreSeasonIcon(onCarTyres.rear_right_tyre.season)} />
                        </span>
                        {onCarTyres.rear_right_tyre.manufacturer}
                      </p>
                      <p>{onCarTyres.rear_right_tyre.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {inStorageTyres && (
          <div className="Tyres">
            <div className="delete-tyre-title-wrapper">
              <h4 className="inStorageTitle">{(t("v8_in_storage").message || "In Storage").toUpperCase()}</h4>
              {inStorageTyres && (
                <Can I="remove-storage-set" the="tyres">
                  <Button className="delete-tyre-btn" disabled={isDeleteButtonDisabled} onClick={handleTyreDelete} type="button" color="light">
                    <Icon className="trash red" />
                  </Button>
                </Can>
              )}
            </div>
            <div className="flex-row">
              <div className="tyre-container">
                <div className="header">
                  <h4>{t("v8_front_tyres").message || "Front tyres"}</h4>
                </div>
                <div className="flex-row padding">
                  <div>
                    <div className="flex-row small-gap start tyre-details-font">
                      <div className="tyre-indicator">
                        <p>L</p>
                      </div>
                      <p>{`${inStorageTyres?.fl_profile} mm`}</p>
                    </div>
                    <div className="tyre-details tyre-details-font">
                      <p>
                        {onCarTyres.front_left_tyre.manufacturer}
                        <span>
                          <Icon className={getTyreSeasonIcon(inStorageTyres.front_left_tyre.season)} />
                        </span>
                      </p>
                      <p>{onCarTyres.front_left_tyre.description}</p>
                    </div>
                  </div>
                  <div>
                    <div className="flex-row small-gap end tyre-details-font">
                      <p>{`${inStorageTyres?.fr_profile} mm`}</p>

                      <div className="tyre-indicator">
                        <p>R</p>
                      </div>
                    </div>
                    <div className="tyre-details tyre-details-font right">
                      <p>
                        <span>
                          <Icon className={getTyreSeasonIcon(inStorageTyres.front_right_tyre.season)} />
                        </span>
                        {onCarTyres.front_right_tyre.manufacturer}
                      </p>
                      <p>{onCarTyres.front_right_tyre.description}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tyre-container">
                <div className="header">
                  <h4>{t("v8_rear_tyres").message || "Rear tyres"}</h4>
                </div>
                <div className="flex-row padding">
                  <div>
                    <div className="flex-row small-gap start tyre-details-font">
                      <div className="tyre-indicator">
                        <p>L</p>
                      </div>
                      <p>{`${inStorageTyres?.rl_profile} mm`}</p>
                    </div>
                    <div className="tyre-details tyre-details-font">
                      <p>
                        {onCarTyres.rear_left_tyre.manufacturer}
                        <span>
                          <Icon className={getTyreSeasonIcon(inStorageTyres.rear_left_tyre.season)} />
                        </span>
                      </p>
                      <p>{onCarTyres.rear_left_tyre.description}</p>
                    </div>
                  </div>
                  <div>
                    <div className="flex-row small-gap end tyre-details-font">
                      <p>{`${inStorageTyres?.rr_profile} mm`}</p>
                      <div className="tyre-indicator">
                        <p>R</p>
                      </div>
                    </div>
                    <div className="tyre-details tyre-details-font right">
                      <p>
                        <span>
                          <Icon className={getTyreSeasonIcon(inStorageTyres.rear_right_tyre.season)} />
                        </span>
                        {onCarTyres.rear_right_tyre.manufacturer}
                      </p>
                      <p>{onCarTyres.rear_right_tyre.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <CustomConfirm
        type={CUSTOM_CONFIRM_TYPES.Danger}
        isOpen={isOpenDeleteModal}
        isLoading={isDeleting}
        handleCancel={() => setIsOpenDeleteModal(false)}
        handleConfirm={() => {
          setIsOpenDeleteModal(false);
          deleteTyreMutation(data!.id);
        }}
        confirmMsg={t("v8_delete_documentation_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action."}
      />
    </>
  );
};
