import { useMemo } from "react";
import { Icon } from "semantic-ui-react";

import { ActionModalSelector, ActionModalSelectorData, AnswerStatusSelector } from "components";
import { Appointment, ChecklistTemplate, QuestionResult } from "models";
import { GenericAttachmentData } from "modules/AppointmentDetails/components/Interventions/components";
import { getExtensionFromURL, getNameFromURL } from "util/common";

type TyreActionsProps = {
  data: QuestionResult;
  appointment: Appointment;
  onSelect: (data: ActionModalSelectorData) => void;
  template?: ChecklistTemplate | null;
  toggleShowMedia?: () => void;
};

const TyreActions = ({ data, template, onSelect, toggleShowMedia, appointment }: TyreActionsProps) => {
  const images = data?.images?.filter(item => item.active)?.map(item => ({ ...item, type: `image/${getExtensionFromURL(item.url)}` })) || [];
  const videos = data?.video?.filter(item => item.active)?.map(item => ({ ...item, type: `video/${getExtensionFromURL(item.url)}` })) || [];

  const attachments: GenericAttachmentData[] = [...images, ...videos].map(attachment => ({
    id: attachment.id,
    created_on: attachment.created_on,
    updated_on: attachment.updated_on,
    url: attachment.url,
    name: getNameFromURL(attachment.url),
    type: attachment.type
  }));

  const isConvertToInterventionButtonDisabled = useMemo(
    () => appointment?.interventions?.some(intervention => intervention.question_result_id === data.id),
    [appointment.interventions, data.id]
  );

  return (
    <div className="tyre-action">
      <div className="tyre-container">
        <AnswerStatusSelector data={data} />
        {(images.length > 0 || videos.length > 0) && (
          <div onClick={toggleShowMedia}>
            <Icon className="images grey pointer" size="small" />
            <p>
              <small>{images.length + videos.length}</small>
            </p>
          </div>
        )}
        <ActionModalSelector
          data={data}
          template={template}
          onSelect={onSelect}
          attachments={attachments}
          isConvertToInterventionButtonDisabled={isConvertToInterventionButtonDisabled}
        />
      </div>
    </div>
  );
};

export default TyreActions;
