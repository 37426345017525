/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useRef, useState } from "react";

import { WebSocketComponent } from "components/WebSocket";
import { queryClient } from "index";
import ApiInstance from "util/Api";
import { queryKeys } from "util/keyFactory";

export const SessionManager = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [tokenExpirationTime, setTokenExpirationTime] = useState<number>(ApiInstance.tokenExpirationTime);

  const handleTokenExpiration = () => {
    const timeRemaining = tokenExpirationTime - Date.now();
    if (timeRemaining <= 0) {
      WebSocketComponent.disconnect();
      queryClient.setQueryData([queryKeys.common.isTokenExpired], true);
    } else {
      setTokenExpirationTimeout();
    }
  };

  const setTokenExpirationTimeout = () => {
    const timeRemaining = tokenExpirationTime - Date.now();
    timeoutRef.current = setTimeout(handleTokenExpiration, timeRemaining);
  };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    } else {
      setTokenExpirationTimeout();
    }
  };

  useEffect(() => {
    setTokenExpirationTime(ApiInstance.tokenExpirationTime);
    setTokenExpirationTimeout();
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [ApiInstance.tokenExpirationTime]);

  return null;
};
