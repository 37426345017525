import { useState } from "react";
import { Icon } from "semantic-ui-react";

import { FilePreview } from "components";
import "components/Images/Images.scss";

type GenericData = {
  id: number;
  url: string;
  visible_in_pdf: boolean;
};

type Attachment = {
  id: number;
  name: string;
  url: string;
  type: string;
  user_id: number;
  appointment_id: number;
  appointment_note_id: number;
  username: string;
  created_on?: string | null;
  updated_on?: string | null;
};

type GenericListProps<T extends GenericData> = {
  data: T[] | undefined;
  canDelete?: boolean;
  onDeleteRequest?: (data: T, index: number) => void;
  onChangeVisibility?: (data: T) => void;
};

export const Images = <T extends GenericData>({ data, canDelete, onDeleteRequest, onChangeVisibility }: GenericListProps<T>) => {
  const [showPreview, setShowPreview] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(0);

  const togglePreview = () => {
    setShowPreview(prev => !prev);
  };

  const handlePreview = (index: number) => {
    setPreviewIndex(index);
    togglePreview();
  };

  const handleChangeVisibility = (e: React.MouseEvent, item: T) => {
    e.stopPropagation();
    if (!onChangeVisibility) return;
    onChangeVisibility(item);
  };

  if (data?.length) {
    return (
      <>
        <div className="Snooze-Images">
          {data.map((item, index) => {
            return (
              <div key={item.id} className="image-view" onClick={() => handlePreview(index)}>
                <img src={item.url} />
                <div className="image-controls">
                  <div className="image-wrapper" onClick={e => handleChangeVisibility(e, item)}>
                    <Icon className={`${item.visible_in_pdf ? "eye green" : "eye slash red"}`} />
                  </div>
                  <div className="btn-group">
                    <div className="image-wrapper">
                      <a target="_blank" href={item.url} rel="noreferrer">
                        <Icon className="download blue" />
                      </a>
                    </div>
                    {onDeleteRequest && canDelete && (
                      <div
                        className="image-wrapper"
                        onClick={e => {
                          e.stopPropagation();
                          onDeleteRequest(item, index);
                        }}
                      >
                        <Icon className="trash grey" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <FilePreview attachments={data as unknown as Attachment[]} currentIndex={previewIndex} open={showPreview} onClose={togglePreview} />
      </>
    );
  }
  return null;
};
