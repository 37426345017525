import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import { Form, Icon, Popup } from "semantic-ui-react";

import { FormUploadImage } from "components";
import { CommunicationSettings } from "models";
import "modules/ComunicationSettings/CustomerCom.scss";
import { ITranslation } from "util/interfaces";

interface DeskCheckInProps {
  tabId: string;
}

export const DeskCheckIn = ({ tabId }: DeskCheckInProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Element name={tabId} className="customer-com-wrapper">
      <h3>{t("v8_desk_check_in").message || "DESK CHECK IN"}</h3>
      <div>
        <Form.Group className="mt-22 file">
          <Form.Field width={8} className="pr-12">
            <label className="small">
              <span>{t("v8_upload_start_image").message || "Upload start image"} (.jpg, .jpeg, .png)</span>
              <Popup
                content={`${t("v8_min_size_of_logo").message || "Min size of Logo"} 100x100 px`}
                position="right center"
                trigger={<Icon className="circle info" />}
              />
            </label>
            <FormUploadImage<CommunicationSettings> path="" name="counter_tablet_home_url" />
          </Form.Field>
        </Form.Group>
      </div>
    </Element>
  );
};
