import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";
import { FormField, FormFieldProps } from "semantic-ui-react";

type TypeFormColorPicker<T extends FieldValues> = {
  name: Path<T>;
  formFieldProps?: FormFieldProps;
};

export const FormColorPicker = <T extends FieldValues>({ name, formFieldProps }: TypeFormColorPicker<T>) => {
  const methods = useFormContext<T>();

  return (
    <Controller
      name={name}
      {...methods}
      render={({ field, fieldState: { invalid, isTouched } }) => (
        <FormField {...formFieldProps} error={invalid && isTouched}>
          <input type="color" {...methods.register(name)} {...field} />
        </FormField>
      )}
    />
  );
};
