import {
  AnswerItem,
  Appointment,
  CHECKLIST_TYPE,
  Car,
  CheckInCommunicationResult,
  CheckInRemark,
  CommunicationEvent,
  Customer,
  QuestionOption,
  QuestionResultImage,
  QuestionResultVideo,
  TyreResult,
  User
} from "models";
import ApiLoadable from "models/ApiLoadable";

export enum COMMUNICATION_STATUS {
  OnlineCheckInSent = 1,
  OnlineCheckInOpened,
  OnlineCheckInAnswered,
  OnlineCheckInClosed,
  DiagnoseOverviewSent,
  DiagnoseOverviewOpened,
  DiagnoseOverviewAnswered,
  DiagnoseOverviewClosed,
  RepairOverviewSent,
  RepairOverviewOpened
}

export enum SMS_GATEWAYS {
  Disabled = 1,
  Chatshipper,
  Twilio
}

export enum COMMUNICATION_EVENTS {
  CheckInSent = 1,
  CheckInRead,
  CheckInAnswered,
  CheckInClosed,
  NotOpenedStatus,
  OpenedStatus,
  AnsweredStatus,
  ClosedStatus,
  PostCommStatus
}

export enum COMMUNICATION_RESULT_STATUS {
  NotAnsweredStatus = 1,
  OkStatus,
  NotOkStatus,
  ContactMeStatus
}

export enum COMMUNICATION_LAYOUT_ID {
  Layout1 = 1,
  Layout2
}

export enum HEADING_POSITIONS {
  HeadingTop = 1,
  HeadingBottom
}

export enum CHANNELS {
  Email = 1,
  Sms,
  Webhook
}

export class CustomerCommunication extends ApiLoadable {
  created_on: string = "";
  updated_on: string = "";
  customer_communication_id: number = 0;
  string: string = "";
  receptionist_key: string = "";
  status: COMMUNICATION_STATUS | null = null;
  correct_phone: string = "";
  correct_email: string = "";
  customer_id: number | null = null;
  appointment_id: number = 0;
  car_id: number = 0;
  receptiont_id: number | null = null;
  note: string = "";
  is_receptionist: boolean = false;
  customer?: Customer;
  appointment: Appointment = new Appointment();
  car: Car = new Car();
  receptionist?: User;
  results?: CommunicationResult[];
  check_in_remarks?: CheckInRemark[];
  diagnose_overview_remarks?: DiagnoseOverviewRemark[];
  check_in_results?: CheckInCommunicationResult[];
  events?: CommunicationEvent[];
}

export class CommunicationResult extends ApiLoadable {
  question_result_id: number = 0;
  customer_communication_id: number = 0;
  tyre_position: number = 0;
  status: COMMUNICATION_RESULT_STATUS = COMMUNICATION_RESULT_STATUS.NotAnsweredStatus;
  check_id: number = 0;
  checklist_type: CHECKLIST_TYPE = CHECKLIST_TYPE.RegularChecklist;
  checklist_name: string = "";
  checklist_order: number = 0;
  group_name: string = "";
  group_order: number = 0;
  question_order: number = 0;

  private _question_options?: QuestionOption[] | null = null;

  get question_options(): QuestionOption[] {
    return this._question_options ? this._question_options : [];
  }

  set question_options(question_options: QuestionOption[] | null | undefined) {
    this._question_options = question_options;
  }

  title: string = "";
  price: number = 0;
  mechanic_fixed: boolean = false;
  mechanic_notes: string = "";
  question_status: number = 0;
  customer_approved: boolean = false;

  private _images?: QuestionResultImage[] | null = null;

  get images(): QuestionResultImage[] {
    return this._images ? this._images : [];
  }

  set images(images: QuestionResultImage[] | null | undefined) {
    this._images = images;
  }

  private _video?: QuestionResultVideo[] | null = null;

  get video(): QuestionResultVideo[] {
    return this._video ? this._video : [];
  }

  set video(video: QuestionResultVideo[] | null | undefined) {
    this._video = video;
  }

  tyre_result: TyreResult = new TyreResult();
  answer_item: AnswerItem = new AnswerItem();
}

export class CommunicationMedium extends ApiLoadable {
  communication_medium_id: number = 0;
  language_code: string = "";
  event_type: number = 0;
  channel: number = 0;
  subject: string = "";
  message: string = "";
  dealer_location_id: number = 0;
}

export class CommunicationLayout extends ApiLoadable {
  id: number = 0;
  description: string = "";
}

export class CommunicationSettings extends ApiLoadable {
  communication_detail_id: number = 0;
  color: string = "";
  logo: string = "";
  welcome_text: string = "";
  welcome_picture: string = "";
  success_text: string = "";
  repair_overview_welcome_text: string = "";
  check_in_welcome_text: string = "";
  check_in_success_text: string = "";
  layout: COMMUNICATION_LAYOUT_ID = COMMUNICATION_LAYOUT_ID.Layout1;
  customcom_remark_enabled: boolean = false;
  checkin_remark_enabled: boolean = false;
  phone_number: string = "";
  email: string = "";
  dealer_location_id: number = 0;
  support_enabled: boolean = false;
  support_text: string = "";
  check_in_testing_mode: boolean = false;
  enable_not_fix_without_status: boolean = false;
  check_in_testing_email: string = "";
  check_in_testing_phone: string = "";
  diagnose_cc_testing_mode: boolean = false;
  diagnose_cc_testing_email: string = "";
  diagnose_cc_testing_phone: string = "";
  repair_cc_testing_mode: boolean = false;
  repair_cc_testing_email: string = "";
  repair_cc_testing_phone: string = "";
  website: string = "";
  report_template_id: number = 0;
  heading_position: HEADING_POSITIONS = HEADING_POSITIONS.HeadingTop;
  send_confirmation_emails: boolean = false;
  enable_not_fixed_advised: boolean = false;
  enable_not_fixed_critical: boolean = false;
  language_code: string = "";
  vat: number = 0;
  include_vat: boolean = false;
  dms_price_enabled: boolean = false;
  total_pdf_enabled: boolean = false;
  dealer_name: string = "";
  location_name: string = "";
  location_street: string = "";
  location_postal_code: string = "";
  location_city: string = "";
  location_country: string = "";
  headline: string = "";
  summer_a_threshold: number = 0;
  summer_n_threshold: number = 0;
  winter_a_threshold: number = 0;
  winter_n_threshold: number = 0;
  all_season_a_threshold: number = 0;
  all_season_n_threshold: number = 0;
  truck_tyre_a_threshold: number = 0;
  truck_tyre_n_threshold: number = 0;
  mechanic_name: string = "";
  show_mechanic_name: boolean = false;
  diagnose_overview_remark_enabled: boolean = false;
  check_in_days_prior: number = 0;
  online_check_in_name_visible: boolean = false;
  send_parking_gate_code: boolean = false;
  diagnose_overview_name_visible: boolean = false;
  counter_tablet_home_url: string = "";
  skip_optional_items: boolean = false;
  hide_lease_intervention_price: boolean = false;
  hide_internal_intervention_price: boolean = false;
  hide_warranty_intervention_price: boolean = false;
  hide_prices_in_desk_communication: boolean = false;
  block_automated_communication_for_lease_customer: boolean = false;
  is_sms_for_online_check_in_enabled: boolean = false;
  is_customer_name_in_kiosk_checkin_enabled: boolean = false;
  is_customer_signature_in_kiosk_checkin_enabled: boolean = false;
  external_js: boolean = false;
  external_css: boolean = false;
  google_analytics_id: boolean = false;
  display_intervention_elements: boolean = false;
  is_employee_name_visible: boolean = false;
  has_multiple_communication_channels: boolean = false;
  is_acses_enabled: boolean = false;
  maintenance_mode: boolean = false;
  online_check_in_send_pin: boolean = false;
  automatically_send_repair_overview_when_quality_check: boolean = false;
  automatically_send_repair_overview_when_car_ready: boolean = false;
  private _layouts?: CommunicationLayout[] | null = null;

  get layouts(): CommunicationLayout[] {
    return this._layouts ? this._layouts : [];
  }

  set layouts(layouts: CommunicationLayout[] | null | undefined) {
    this._layouts = layouts;
  }
}

export class CustomComRemark extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  customcom_id: number = 0;
  title: string = "";
  description: string = "";
  customer_name: string = "";
  receptionist_handled: boolean = false;

  private _attachments?: CustomComAttachment[] | null = null;

  get attachments(): CustomComAttachment[] {
    return this._attachments ? this._attachments : [];
  }

  set attachments(attachments: CustomComAttachment[] | null | undefined) {
    this._attachments = attachments;
  }
}

export class CustomComAttachment extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  url: string = "";
  name: string = "";
  customcom_id: number = 0;
  customcom_remarks_id: number | null = null;
}

export class ChatshipperConversation extends ApiLoadable {
  id: string = "";
  phone_nr: string = "";
}

export class DiagnoseOverviewRemark extends CheckInRemark {}
