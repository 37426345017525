import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Icon } from "semantic-ui-react";

import { CHANNELS, KEYLOCKER_COMMUNICATION_EVENT_TYPE, KEYLOCKER_PIN_REQUEST_TYPES, KeylockerCommunication, KeylockerCommunicationEvent } from "models";
import { ITranslation } from "util/interfaces";

type EventInfoProps = {
  comm: KeylockerCommunication;
  event: KeylockerCommunicationEvent;
};

export const EventInfo = ({ event, comm }: EventInfoProps) => {
  const t = useTranslation().t as ITranslation;

  const fullname =
    (event.type as unknown as KEYLOCKER_COMMUNICATION_EVENT_TYPE) === KEYLOCKER_COMMUNICATION_EVENT_TYPE.KeylockerDropPinSent &&
    comm.customer &&
    `${comm.customer.firstname} ${comm.customer.surname}`;

  const getPinRequestType = (event: KeylockerCommunicationEvent) => {
    const CommonText = t("v8_pin_requested_using").message || "Pin requested using";

    switch (event.pin_request_type) {
      case KEYLOCKER_PIN_REQUEST_TYPES.Phone:
        return <span>{`${CommonText}: ${t("v8_phone_number").message || "Phone number"}`}</span>;
      case KEYLOCKER_PIN_REQUEST_TYPES.RegistrationNumber:
        return <span>{`${CommonText}: ${t("v8_reg_number").message || "Registration number"}`}</span>;
      case KEYLOCKER_PIN_REQUEST_TYPES.OnlineCheckIn:
        return <span>{`${CommonText}: ${t("v8_online_check_in").message || "Online check-in"}`}</span>;
      default:
        return null;
    }
  };

  return (
    <Grid.Row width={16} className="event-info-rows">
      {event.receivers?.map((receiver, i) => (
        <React.Fragment key={i}>
          <span>
            {receiver.communication_channel_id === CHANNELS.Email ? <Icon name="mail" color="green" /> : <Icon name="phone" color="green" />}
            {receiver.destination}
          </span>
          {receiver.reason && (
            <span className="-margin-left-10">
              <Icon key={i} name="times circle outline" color="red" />
              {receiver.reason}
            </span>
          )}
        </React.Fragment>
      ))}

      {fullname && (
        <span>
          <Icon name="user" color="green" />
          {fullname}
        </span>
      )}

      {getPinRequestType(event)}

      {event.keylocker_name && <span>{`${t("keylocker").message || "Keylocker"}: ${event.keylocker_name}`}</span>}
      {event.keylocker_box_number && <span>{`${t("box_number").message || "Box number"}: ${event.keylocker_box_number}`}</span>}
    </Grid.Row>
  );
};
