import moment from "moment";
import { ChangeEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownProps, Input, InputOnChangeData, Loader } from "semantic-ui-react";

import { DatePicker } from "components/DatePicker";
import { useRegisterNavAction } from "hooks";
import { EXACT_ACTION_TYPES } from "models";
import { ExactFailuresTable } from "modules/ExactFailures/components/ExactFailuresTable/ExactFailuresTable";
import "modules/ExactFailures/ExactFailures.scss";
import { useExactFailures } from "modules/ExactFailures/hooks/useExactFailures";
import { ITranslation } from "util/interfaces";

const actionOptions = Object.entries(EXACT_ACTION_TYPES)
  .filter(([key]) => isNaN(Number(key)))
  .map(([key, value]) => ({
    key,
    text: key,
    value: Number(value)
  }));

const ExactFailures = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [action, setAction] = useState<number | null>(null);
  const [statusCode, setStatusCode] = useState<number | null>(null);
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();

  const t = useTranslation().t as ITranslation;

  const filters = useMemo(
    () => ({
      action,
      status_code: statusCode,
      date_from: dateFrom ? moment(dateFrom).startOf("day").toISOString() : null,
      date_to: dateTo ? moment(dateTo).endOf("day").toISOString() : null,
      page: currentPage
    }),
    [action, statusCode, dateFrom, dateTo, currentPage]
  );

  const { data, isLoading, invalidateFailures } = useExactFailures(filters);

  const handleChangeDropdown = (_e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const selectedAction = data.value ? Number(data.value) : null;

    setAction(selectedAction);
    setCurrentPage(1);
  };

  const handleStatusCodeChange = (_e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setStatusCode(data.value ? Number(data.value) : null);
    setCurrentPage(1);
  };

  const handleDateChange = (dates: Date[]) => {
    if (!Array.isArray(dates)) return;

    const [start, end] = dates;
    setDateFrom(start);
    setDateTo(end);
    setCurrentPage(1);
  };

  const handlePageChange = (_e: React.MouseEvent<HTMLAnchorElement> | undefined, data: { activePage: number }) => {
    setCurrentPage(data.activePage);
  };

  const refreshAction = useMemo(
    () => ({
      onClick: invalidateFailures
    }),
    [invalidateFailures]
  );

  useRegisterNavAction(refreshAction.onClick);

  return (
    <div className="ExactDashboard">
      <div className="ExactDashboard__filters">
        <Dropdown
          clearable
          placeholder={t("select_an_action").message || "Select an action"}
          options={actionOptions}
          value={action ?? undefined}
          fluid
          selection
          search
          onChange={handleChangeDropdown}
        />

        <Input type="number" value={statusCode || ""} onChange={handleStatusCodeChange} placeholder={t("enter_status_code").message || "Enter status code"} />

        <DatePicker startDate={dateFrom} endDate={dateTo} onDateChange={handleDateChange} selectsRange showLabel clearable />
      </div>

      <div className="ExactDashboard__container">
        {isLoading ? (
          <Loader active />
        ) : (
          <ExactFailuresTable
            failures={data?.failures}
            activePage={currentPage}
            totalPages={data?.nb_pages || 1}
            onPageChange={handlePageChange}
            totalItems={data?.nb_items || 0}
          />
        )}
      </div>
    </div>
  );
};

export default ExactFailures;
