import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Input } from "semantic-ui-react";

import { UploadFilePreview } from "components";
import { useUploadImage } from "components/Form/FormUploadImage/hooks/useUploadImage";
import "components/UploadImage/UploadImage.scss";
import { ITranslation } from "util/interfaces";

interface UploadImageProps<T extends FieldValues> {
  name: Path<T>;
  path: string;
  onSuccess?: (url: string) => void;
  onError?: (reason: string) => void;
  fileFieldName?: string;
  formFieldProps?: React.HTMLAttributes<HTMLDivElement>;
}

export const FormUploadImage = <T extends FieldValues>({ name, path, onSuccess, onError, fileFieldName = name, formFieldProps }: UploadImageProps<T>) => {
  const methods = useFormContext<T>();
  const { watch } = useFormContext();
  const image = watch(name);
  const t = useTranslation().t as ITranslation;
  const { handleChangeFile, handleDelete, handleUpload } = useUploadImage<T>();

  return (
    <Controller
      name={name}
      {...methods}
      render={({ field, fieldState }) => {
        return (
          <div className="upload-container" {...formFieldProps}>
            <div className="UploadImage new">
              <div className="button" onClick={() => handleUpload(name)}>
                <p className="btn-text">{t("v8_choose_file").message || "Choose File"}</p>
              </div>
              <p className="fileInput-ellipsis">{t(name).message || t("v8_choose_file").message || "Choose File"}</p>
            </div>
            <Input
              id={name}
              accept="image/png, image/gif, image/jpeg"
              type="file"
              style={{ display: "none" }}
              onChange={e => handleChangeFile(e, fileFieldName, path, field, onSuccess, onError)}
            />
            <UploadFilePreview image={image} onDelete={() => handleDelete(field)} />
            {fieldState.invalid && <div className="error">{t("v8_something_went_wrong").message}</div>}
          </div>
        );
      }}
    />
  );
};
