import moment from "moment";
import { useState } from "react";
import { Button } from "semantic-ui-react";

import RDWDetails from "components/RDW/components/RDWDetails";
import useRdw from "components/RDW/hooks/useRdw";
import "components/RDW/RDW.scss";

type RDWProps = {
  regNumber?: string;
  country?: string | null;
};

export const RDW = ({ regNumber, country }: RDWProps) => {
  if (!country || country !== "NL") return null;

  const [isRdwDetailsDisplayed, setIsRdwDetailsDisplayed] = useState(false);

  const { data, fetchRDWData, loading } = useRdw(regNumber);

  const handleGetRdwDate = () => {
    fetchRDWData();
  };

  const handleToggleIsRdwDetailsOpen = () => {
    setIsRdwDetailsDisplayed(isRdwDetailsDisplayed => {
      if (!isRdwDetailsDisplayed) {
        handleGetRdwDate();
      }
      return !isRdwDetailsDisplayed;
    });
  };

  const expiredDate = (data?.vervaldatum_tachograaf && moment().isAfter(moment(data?.vervaldatum_tachograaf))) || moment().isAfter(moment(data?.vervaldatum_apk));

  return (
    <div className="RDW">
      <Button className={`-appointment-status ${expiredDate ? "expired" : ""}`} size="small" loading={loading} onClick={handleToggleIsRdwDetailsOpen}>
        RDW
      </Button>
      {isRdwDetailsDisplayed && <RDWDetails data={data} loading={loading} onClose={handleToggleIsRdwDetailsOpen} onRefresh={handleGetRdwDate} />}
    </div>
  );
};
