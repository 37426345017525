import moment from "moment";
import TimePicker from "rc-time-picker";
import { ChangeEvent, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Checkbox, Form, Icon, Input, Modal, Search, SearchResultData } from "semantic-ui-react";

import { Can } from "components";
import InterventionModal from "components/InterventionModal";
import { useDealersLocations } from "hooks";
import { Appointment, Customer, DMS } from "models";
import { InterventionData } from "modules/AppointmentDetails/hooks/useIntervention";
import { AppointmentData, KeyloopCustomerPartial, useNewAppointment } from "modules/Appointments/components/NewAppointment/hooks";
import "modules/Appointments/components/NewAppointment/NewAppointment.scss";
import { debounce } from "util/common";
import { ITranslation } from "util/interfaces";

// eslint-disable-next-line import/order
import "rc-time-picker/assets/index.css";

type NewAppointmentProps = {
  showCarInShop?: boolean;
};

type AppointmentWithoutCustomerDriver = Omit<Appointment, "customer_driver">;

interface PartialAppointment extends AppointmentWithoutCustomerDriver {
  customer_driver?: Customer | KeyloopCustomerPartial | undefined;
}

const minSearchTermLength = 4;

const formatRegistrationNumber = (reg: string) => {
  if (reg?.length < 2) return reg;

  let newReg = reg[0];
  for (let i = 1; i < reg.length; i++) {
    if (reg[i - 1] !== "-" && reg[i] !== "-" && /\d/.test(reg[i - 1]) !== /\d/.test(reg[i])) newReg += "-";
    newReg += reg[i];
  }

  return newReg;
};

const randomString = (length: number) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

const initAppData = {
  due_in_time: moment().toISOString(),
  time_car_app: moment().toISOString(),
  car: {
    apk_date_dms: moment().set("hour", 12).toISOString()
  }
} as PartialAppointment;

export const NewAppointment = ({ showCarInShop }: NewAppointmentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedLocation, isKeyLoopLocation } = useDealersLocations();
  const [isInterventionModalOpen, setIsInterventionModalOpen] = useState(false);
  const [appointmentData, setAppointmentData] = useState<PartialAppointment>(initAppData);
  const [interventions, setInterventions] = useState<InterventionData[] | null>(null);
  const { cars, customers, searchCustomer, searchCar, addAppointment, searchKeyloopCustomer, selectKeyloopCustomer, keyloopCustomer } = useNewAppointment();
  const [pressedButtonName, setPressedButtonName] = useState<"findCar" | "findCustomer" | "">("");
  const t = useTranslation().t as ITranslation;

  const toggleInterventionModal = () => {
    setIsInterventionModalOpen(prev => !prev);
  };

  const [hasValidationError, setHasValidationError] = useState(false);

  const [carSearchTerm, setCarSearchTerm] = useState<string>("");
  const [customerSearchTerm, setCustomerSearchTerm] = useState<string>("");
  const toggleModal = () => {
    setAppointmentData(initAppData);
    setInterventions(null);
    setPressedButtonName("");
    setCarSearchTerm("");
    setCustomerSearchTerm("");
    setIsOpen(prev => !prev);
  };

  const handleAddIntervention = (data: InterventionData) => {
    setInterventions(prev => {
      if (prev?.length) {
        return [...prev, data];
      }
      return [data];
    });
  };

  const handleNewAppointment = () => {
    let hasError = false;

    if (!appointmentData?.car?.make || appointmentData?.car?.make.trim() === "") {
      hasError = true;
    } else if (!appointmentData?.car?.model || appointmentData?.car?.model.trim() === "") {
      hasError = true;
    } else if (!appointmentData?.customer_driver?.surname || appointmentData?.customer_driver?.surname.trim() === "") {
      hasError = true;
    } else if (!isKeyLoopLocation && (!appointmentData?.wo_nr || appointmentData?.wo_nr.trim() === "")) {
      hasError = true;
    } else if (!appointmentData?.car_return_time) {
      hasError = true;
    }

    if ((isKeyLoopLocation && !appointmentData?.customer_driver?.dms_nr) || appointmentData?.customer_driver?.dms_nr?.trim() === "") {
      hasError = true;
    }

    if (hasError) {
      setHasValidationError(true);
      return;
    }

    const { car_return_time, time_car_app, due_in_time } = appointmentData;
    const due_in_date = moment(time_car_app).format("YYYY-MM-DD");

    const appointmentToSubmit = { ...appointmentData } as AppointmentData;
    appointmentToSubmit.interventions = interventions;
    appointmentToSubmit.car_return_time = moment(car_return_time).toISOString();
    appointmentToSubmit.due_in_time = moment(due_in_time).format("HH:mm");
    appointmentToSubmit.due_in = moment(due_in_date + " " + appointmentToSubmit.due_in_time).toISOString();
    appointmentToSubmit.car_in_shop = null;
    appointmentToSubmit.dms_nr = `claire_${moment().utc().toISOString()}_${randomString(10)}`;
    if (selectedLocation?.id) {
      appointmentToSubmit.dealer_location_id = selectedLocation?.id;
    }
    if (appointmentToSubmit.customer_driver && !appointmentToSubmit.customer_driver.dms_nr) {
      appointmentToSubmit.customer_driver.dms_nr = `claire_${moment().utc().toISOString()}`;
    }

    addAppointment.mutateAsync(appointmentToSubmit).then(() => {
      setAppointmentData(initAppData);
      toggleModal();
    });
  };

  useEffect(() => {
    setHasValidationError(false);
  }, [appointmentData]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (["reg_nr", "apk_date_dms", "dms_nr", "make", "model", "vin_nr"].includes(name)) {
      setAppointmentData(prevData => {
        return {
          ...prevData,
          car: {
            ...prevData?.car,
            [name]: value
          }
        } as PartialAppointment;
      });
    } else if (["dms_nr", "firstname", "surname", "street", "postcode", "place", "email_business", "tel_mobile_business", "tel_business_nr"].includes(name)) {
      setAppointmentData(prevData => {
        return {
          ...prevData,
          customer_driver: {
            ...prevData?.customer_driver,
            [name]: value
          }
        } as PartialAppointment;
      });
    } else {
      setAppointmentData(prevData => {
        return {
          ...prevData,
          [name]: value
        };
      });
    }
  };

  const handleOnDateChange = ({ name, value }: { name: string; value: string | boolean | null | number }) => {
    setAppointmentData(prevData => {
      return {
        ...prevData,
        [name]: value
      };
    });
  };

  const handleCustomerSearch = (searchTerm?: string) => {
    if (isKeyLoopLocation) {
      setPressedButtonName("findCustomer");
      searchKeyloopCustomer.mutate(appointmentData.car?.dms_nr as string);
    } else if (searchTerm && searchTerm.length >= minSearchTermLength) {
      searchCustomer.mutate(searchTerm);
    } else if (appointmentData?.car?.reg_nr && appointmentData.car.reg_nr.length >= minSearchTermLength) {
      setPressedButtonName("findCustomer");
      searchCustomer.mutate(isKeyLoopLocation ? appointmentData.car.dms_nr : appointmentData.car.reg_nr);
    }
  };

  const handleCarSearch = (searchTerm?: string) => {
    if (searchTerm && searchTerm.length >= minSearchTermLength) {
      searchCar.mutate(searchTerm);
    } else if (carSearchTerm && carSearchTerm.length >= minSearchTermLength) {
      setPressedButtonName("findCar");
      searchCar.mutate(carSearchTerm);
    }
  };

  const debouncedCarSearch = debounce((value: string) => {
    handleCarSearch(value);
  }, 500);

  const debouncedCustomerSearch = debounce((value: string) => {
    handleCustomerSearch(value);
  }, 500);

  const handleCarSearchChange = (value: string) => {
    const carSearchTerm = [DMS.KeyLoopMenus, DMS.KeyLoopJobs].includes(Number(selectedLocation?.dms_id)) ? formatRegistrationNumber(value) : value;
    setCarSearchTerm(carSearchTerm);
    debouncedCarSearch(carSearchTerm);
  };

  const handleCarResultSelect = (data: SearchResultData) => {
    const car = data?.result?.car;
    if (isKeyLoopLocation && (!car.reg_nr || !car.make || !car.model || !car.vin_nr)) {
      toast.error(t("v8_missing_values_in_dms").message || "Missing values must be added in the DMS!");
    }
    setCustomerSearchTerm(car.reg_nr);

    setAppointmentData(prevData => {
      return {
        ...prevData,
        car
      };
    });
  };

  const handleCustomerSearchChange = (value: string) => {
    setCustomerSearchTerm(value);
    debouncedCustomerSearch(value);
  };

  const handleCustomerResultSelect = (data: SearchResultData) => {
    if (isKeyLoopLocation) {
      const car = appointmentData?.car;

      if (car) {
        if (!car.reg_nr || !car.make || !car.model || !car.vin_nr) {
          toast.error(t("v8_missing_values_in_dms").message || "Missing values must be added in the DMS!");
        } else {
          selectKeyloopCustomer.mutate(data.result.id);

          setAppointmentData(prevData => {
            return {
              ...prevData,
              customer_driver: keyloopCustomer as KeyloopCustomerPartial
            };
          });
        }
      }
    } else {
      const customerDriver = data.result.customer_driver;
      (customerDriver.firstname = customerDriver.firstname || ""),
        (customerDriver.surname = customerDriver.surname || ""),
        (customerDriver.street = customerDriver.street || ""),
        (customerDriver.postcode = customerDriver.postcode || ""),
        (customerDriver.place = customerDriver.place || ""),
        (customerDriver.email_business = customerDriver.email_business || ""),
        (customerDriver.tel_mobile_business = customerDriver.tel_mobile_business || ""),
        (customerDriver.tel_business_nr = customerDriver.tel_business_nr || ""),
        (customerDriver.dms_nr = customerDriver.dms_nr || ""),
        setAppointmentData(prevData => {
          return {
            ...prevData,
            customer_driver: customerDriver
          };
        });
    }
  };

  const toggleInterventionCustomerOk = (key: number) => {
    setInterventions(prev => {
      if (prev?.length) {
        return prev.map((intervention, index) => {
          if (index === key) {
            return {
              ...intervention,
              customer_ok: !intervention.customer_ok
            };
          }
          return intervention;
        });
      }
      return prev;
    });
  };

  const handleDeleteIntervention = (key: number) => {
    setInterventions(prev => {
      if (prev?.length) {
        return prev.filter((_intervention, index) => index !== key);
      }
      return prev;
    });
  };

  const updateInterventionData = (key: number, data: Partial<InterventionData>) => {
    setInterventions(prev => {
      if (prev?.length) {
        return prev.map((intervention, index) => {
          if (index === key) {
            return {
              ...intervention,
              ...data
            };
          }
          return intervention;
        });
      }
      return prev;
    });
  };

  useEffect(() => {
    if (keyloopCustomer) {
      setAppointmentData(prevData => {
        return {
          ...prevData,
          customer_driver: keyloopCustomer
        };
      });
    }
  }, [keyloopCustomer]);

  return (
    <div className="NewAppointment">
      <Button icon labelPosition="left" onClick={toggleModal}>
        <Icon className="plus large" color="green" />
        {t("v8_appointment").message || "Appointment"}
      </Button>
      <Modal key={String(isOpen)} open={isOpen} className="NewAppointment" size="fullscreen">
        <Modal.Header>{t("create_workorder").message || "Create Workorder"}</Modal.Header>
        <Modal.Content>
          <Form id="new-appointment" onSubmit={handleNewAppointment} error onChange={handleOnChange}>
            <Form.Group>
              <Form.Field className="dropdown-search" width={6}>
                <label className="big">
                  <Icon className="car mirrors" />
                  {t("v8_car").message || "Car"}
                </label>
                <div className="search">
                  <Search
                    fluid={true}
                    icon="magnifying glass"
                    noResultsMessage={t("v8_no_results").message || "No results found"}
                    loading={searchCar.isPending && pressedButtonName !== "findCar"}
                    minCharacters={minSearchTermLength}
                    onSearchChange={(_e, { value }) => handleCarSearchChange(String(value))}
                    onResultSelect={(_e, data) => handleCarResultSelect(data)}
                    resultRenderer={({ title }) => <p>{title}</p>}
                    results={cars}
                    value={carSearchTerm}
                  />
                  <Button color="green" type="button" className="-appointment-statu left-icon mt-14 rowButton" onClick={() => handleCustomerSearch()}>
                    <Icon className="user" />
                    {t("v8_find_customer").message || "Find Customer"}
                  </Button>
                </div>
              </Form.Field>
              {customers.length > 0 && pressedButtonName === "findCustomer" && (
                <Form.Field className="dropdown-search" width={6}>
                  <label className="big">
                    <Icon className="user" />
                    {t("v8_customer").message || "Customer"}
                  </label>
                  <div className="search rounded">
                    <Search
                      fluid={true}
                      icon="magnifying glass"
                      noResultsMessage={t("v8_no_results").message || "No results found"}
                      loading={searchCustomer.isPending}
                      minCharacters={minSearchTermLength}
                      onSearchChange={(_e, { value }) => handleCustomerSearchChange(String(value))}
                      onResultSelect={(_e, data) => handleCustomerResultSelect(data)}
                      resultRenderer={({ title }) => <p>{title}</p>}
                      results={customers}
                      defaultOpen
                    />
                  </div>
                </Form.Field>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Field width={6}>
                <label>{t("v8_registration").message || "Registration"}</label>
                <Input
                  placeholder={t("v8_registration").message || "Registration"}
                  fluid
                  name="reg_nr"
                  value={appointmentData?.car?.reg_nr}
                  disabled={isKeyLoopLocation as boolean}
                />
              </Form.Field>

              <Form.Field width={6} required error={hasValidationError && !appointmentData?.car?.make}>
                <label>{t("v8_make").message || "Make"}</label>
                <Input
                  placeholder={t("v8_make").message || "Make"}
                  fluid
                  name="make"
                  required
                  value={appointmentData?.car?.make}
                  disabled={isKeyLoopLocation as boolean}
                />
              </Form.Field>

              <Form.Field width={6} required error={hasValidationError && !appointmentData?.car?.model}>
                <label>{t("v8_model").message || "Model"}</label>
                <Input
                  placeholder={t("v8_model").message || "Model"}
                  fluid
                  name="model"
                  required
                  value={appointmentData?.car?.model}
                  disabled={isKeyLoopLocation as boolean}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width={8} required error={hasValidationError && !appointmentData?.car?.apk_date_dms}>
                <label>{t("v8_apk_date").message || "APK Date"}</label>
                <DatePicker
                  className="date"
                  readOnly
                  placeholderText={t("v8_apk_date").message}
                  dateFormat="dd-MM-yyyy"
                  todayButton={t("v8_today").message || "Today"}
                  showMonthDropdown
                  showYearDropdown
                  selected={appointmentData?.car?.apk_date_dms ? moment(appointmentData.car.apk_date_dms).toDate() : moment().set("hour", 12).toDate()}
                  onChange={() => null}
                  onChangeRaw={e => e?.preventDefault()}
                />
              </Form.Field>

              <Form.Field width={8}>
                <label>{t("v8_vin").message || "Vin #"}</label>
                <Input placeholder={t("v8_vin").message || "Vin"} fluid name="vin_nr" value={appointmentData?.car?.vin_nr} disabled={isKeyLoopLocation as boolean} />
              </Form.Field>
            </Form.Group>

            <Form.Group className="mt-25">
              {!isKeyLoopLocation && (
                <Form.Field className="dropdown-search" width={6}>
                  <label className="big">
                    <Icon className="user" />
                    {t("v8_customer").message || "Customer"}
                  </label>
                  <div className="search car">
                    <Search
                      fluid={true}
                      icon="magnifying glass"
                      noResultsMessage={t("no_results").message || "No results found"}
                      loading={searchCustomer.isPending && pressedButtonName !== "findCustomer"}
                      minCharacters={minSearchTermLength}
                      onSearchChange={(_e, { value }) => handleCustomerSearchChange(String(value))}
                      onResultSelect={(_e, data) => handleCustomerResultSelect(data)}
                      resultRenderer={({ title }) => <p>{title}</p>}
                      results={customers}
                      value={customerSearchTerm}
                    />
                    <Button color="green" type="button" className="-appointment-statu left-icon mt-14 rowButton" onClick={() => handleCarSearch()}>
                      <Icon className="car mirrors" />
                      {t("v8_find_car").message || "Find Car"}
                    </Button>
                  </div>
                </Form.Field>
              )}

              {cars.length > 0 && pressedButtonName === "findCar" && (
                <Form.Field className="dropdown-search" width={6}>
                  <label className="big">
                    <Icon className="car mirrors" />
                    {t("v8_car").message || "Car"}
                  </label>
                  <div className="search">
                    <Search
                      fluid={true}
                      icon="magnifying glass"
                      noResultsMessage={t("no_results").message || "No results found"}
                      loading={searchCar.isPending}
                      minCharacters={minSearchTermLength}
                      onSearchChange={(_e, { value }) => handleCarSearchChange(String(value))}
                      onResultSelect={(_e, data) => handleCarResultSelect(data)}
                      resultRenderer={({ title }) => <p>{title}</p>}
                      results={cars}
                      defaultOpen
                    />
                  </div>
                </Form.Field>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Field width={6} required={isKeyLoopLocation}>
                <label>{`${t("v8_dms").message || "DMS"} #`}</label>
                <Input
                  placeholder={`${t("v8_dms").message || "DMS"} #`}
                  fluid
                  name="dms_nr"
                  value={appointmentData?.customer_driver?.dms_nr}
                  required={isKeyLoopLocation}
                  disabled={Boolean((isKeyLoopLocation as boolean) || (appointmentData.customer_driver_id && appointmentData.customer_driver_id > 0))}
                />
              </Form.Field>

              <Form.Field width={6}>
                <label>{t("v8_first_name").message || "First name"}</label>
                <Input
                  placeholder={t("v8_first_name").message || "First name"}
                  fluid
                  name="firstname"
                  value={appointmentData?.customer_driver?.firstname}
                  disabled={isKeyLoopLocation as boolean}
                />
              </Form.Field>

              <Form.Field width={6} required error={hasValidationError && !appointmentData?.customer_driver?.surname}>
                <label>{t("v8_last_name").message || "Last name"}</label>
                <Input
                  placeholder={t("v8_last_name").message || "Last name"}
                  fluid
                  name="surname"
                  required
                  value={appointmentData?.customer_driver?.surname}
                  disabled={isKeyLoopLocation as boolean}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width={6}>
                <label>{t("v8_street_house").message || "Street and house #"}</label>
                <Input
                  placeholder={t("v8_street_house").message || "Street and house #"}
                  fluid
                  name="street"
                  value={appointmentData?.customer_driver?.street}
                  disabled={isKeyLoopLocation as boolean}
                />
              </Form.Field>

              <Form.Field width={6}>
                <label>{t("v8_postcode").message || "Postcode"}</label>
                <Input
                  placeholder={t("v8_postcode").message || "Postcode"}
                  fluid
                  name="postcode"
                  value={appointmentData?.customer_driver?.postcode}
                  disabled={isKeyLoopLocation as boolean}
                />
              </Form.Field>

              <Form.Field width={6}>
                <label>{t("v8_location").message || "Location"}</label>
                <Input
                  placeholder={t("v8_location").message || "Location"}
                  fluid
                  name="place"
                  value={appointmentData?.customer_driver?.place}
                  disabled={isKeyLoopLocation as boolean}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width={6}>
                <label>{t("v8_email").message || "Email"}</label>
                <Input
                  placeholder={t("v8_email").message || "Email"}
                  fluid
                  name="email_business"
                  value={appointmentData?.customer_driver?.email_business}
                  disabled={isKeyLoopLocation as boolean}
                />
              </Form.Field>

              <Form.Field width={6}>
                <label>{t("v8_mobile").message || "Mobile"}</label>
                <Input
                  placeholder={t("v8_mobile").message || "Mobile"}
                  fluid
                  name="tel_mobile_business"
                  value={appointmentData?.customer_driver?.tel_mobile_business}
                  disabled={isKeyLoopLocation as boolean}
                />
              </Form.Field>

              <Form.Field width={6}>
                <label>{t("v8_phone").message || "Phone"}</label>
                <Input
                  placeholder={t("v8_phone").message || "Phone"}
                  fluid
                  name="tel_business_nr"
                  value={appointmentData?.customer_driver?.tel_business_nr}
                  disabled={isKeyLoopLocation as boolean}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group className="mt-25">
              <label className="big">
                <Icon className="list" />
                {t("v8_appointment").message || "Appointment"}
              </label>
            </Form.Group>

            <Form.Group>
              <Form.Field width={6} required error={hasValidationError && !appointmentData?.time_car_app}>
                <label>{t("v8_appointment_date").message || "Appointment date *"}</label>
                <DatePicker
                  placeholderText={t("v8_appointment_date").message}
                  dateFormat="dd-MM-yyyy"
                  todayButton={t("v8_today").message || "Today"}
                  showMonthDropdown
                  showYearDropdown
                  minDate={moment().toDate()}
                  selected={moment(appointmentData?.time_car_app).toDate()}
                  onChange={date => handleOnDateChange({ value: moment(date).toISOString(), name: "time_car_app" })}
                  onChangeRaw={e => e?.preventDefault()}
                />
              </Form.Field>

              <Form.Field width={6}>
                <label>{`${t("v8_ref").message || "Ref"} #`}</label>
                <Input placeholder={`${t("v8_ref").message || "Ref"} #`} fluid name="ref_nr" />
              </Form.Field>

              {!isKeyLoopLocation && (
                <Form.Field width={6} required error={hasValidationError && !appointmentData?.wo_nr}>
                  <label>{t("v8_wo").message || "WO"}</label>
                  <Input placeholder={t("v8_wo").message || "WO"} fluid name="wo_nr" required />
                </Form.Field>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Field width={8} required>
                <label>{t("v8_scheduled_in_date").message || "Scheduled-in date"}</label>
                <DatePicker
                  placeholderText={t("v8_scheduled_in_date").message}
                  readOnly
                  dateFormat="dd-MM-yyyy"
                  todayButton={t("v8_today").message || "Today"}
                  showMonthDropdown
                  showYearDropdown
                  selected={moment(appointmentData?.time_car_app).toDate()}
                  onChange={() => null}
                  onChangeRaw={e => e?.preventDefault()}
                />
              </Form.Field>

              <Form.Field width={8} required>
                <label>{t("v8_scheduled_in_time").message || "Scheduled-in time"}</label>
                <TimePicker
                  showSecond={false}
                  onChange={date => handleOnDateChange({ value: date ? moment(date).toISOString() : moment().toISOString(), name: "due_in_time" })}
                  value={moment(appointmentData?.due_in_time)}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width={8} required error={hasValidationError && !appointmentData?.car_return_time}>
                <label>{t("v8_scheduled_out").message || "Scheduled-out"}</label>
                <DatePicker
                  placeholderText={t("v8_scheduled_out").message || "Scheduled out"}
                  dateFormat="dd-MM-yyyy h:mm"
                  todayButton={t("v8_today").message || "Today"}
                  showMonthDropdown
                  showYearDropdown
                  showTimeInput
                  minDate={moment().toDate()}
                  selected={appointmentData?.car_return_time ? moment(appointmentData.car_return_time).toDate() : moment(new Date()).toDate()}
                  onChange={date => handleOnDateChange({ value: moment(date).toISOString(), name: "car_return_time" })}
                  onChangeRaw={e => e?.preventDefault()}
                />
              </Form.Field>

              {showCarInShop && (
                <Form.Field width={8}>
                  <label>{t("car_in_shop").message || "Car in shop"}</label>
                  <Checkbox toggle onChange={(_e, { checked }) => handleOnDateChange({ name: "car_in_shop", value: checked ? checked : null })} name="car_in_shop" />
                </Form.Field>
              )}
            </Form.Group>

            {interventions?.map((intervention, index: number) => {
              return (
                <Form.Group key={index}>
                  <Form.Field width={4}>
                    <label>{t("v8_intervention").message || "Intervention"}</label>
                    <Input
                      placeholder={t("v8_intervention").message || "Intervention"}
                      fluid
                      value={intervention.title}
                      onChange={e => updateInterventionData(index, { title: e.target.value })}
                    />
                  </Form.Field>

                  <Form.Field width={4}>
                    <label>{t("v8_remark").message || "Remark"}</label>
                    <Input
                      placeholder={t("v8_remark").message || "Remark"}
                      fluid
                      value={intervention.description}
                      onChange={e => updateInterventionData(index, { description: e.target.value })}
                    />
                  </Form.Field>

                  <Form.Field width={1} className="mt-25 intervention-actions">
                    <Button onClick={() => toggleInterventionCustomerOk(index)} type="button" className="wdLightGrey centerIcon greyBorder customer">
                      <Icon className={`user check ${intervention.customer_ok ? "green" : "red"}`} />
                    </Button>
                  </Form.Field>

                  <Form.Field width={1} className="mt-25 intervention-actions">
                    <Button onClick={() => handleDeleteIntervention(index)} type="button" className="wdLightGrey centerIcon greyBorder customer">
                      <Icon className={`trash red`} />
                    </Button>
                  </Form.Field>
                </Form.Group>
              );
            })}
            <Can I="add" the="interventions">
              <Form.Group>
                <Button color="green" type="button" className="-appointment-statu left-icon mt-14 ml-0" onClick={toggleInterventionModal}>
                  <Icon className="plus" />
                  {t("v8_add_intervention").message || "Add Intervention"}
                </Button>
              </Form.Group>
            </Can>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <div className="action-buttons">
            <Button color="light" onClick={toggleModal} type="reset">
              {t("v8_cancel").message || "Cancel"}
              <Icon className="xmark" />
            </Button>
            <Button color="green" onClick={handleNewAppointment} loading={addAppointment.isPending}>
              {t("v8_save").message || "Save"}
              <Icon className="check" />
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
      <InterventionModal showFileUpload={false} open={isInterventionModalOpen} onClose={toggleInterventionModal} onSubmitData={handleAddIntervention} />
    </div>
  );
};
