import { saveAs } from "file-saver";
import moment from "moment";
import { Icon } from "semantic-ui-react";

import { UploadVideo } from "components";
import "components/Videos/Videos.scss";
import { useDealersLocations } from "hooks";
import { Appointment } from "models/";

type GenericData = {
  id: number;
  url: string;
  visible: boolean;
};

type GenericListProps<T extends GenericData> = {
  data: T[] | undefined;
  appointment: Appointment;
  isEditable?: boolean;
  handleDeleteVideo?: (data: T, index: number) => void;
  handleVideoUpload?: (data: T, index: number, url: string) => void;
  handleVideoVisibility?: (data: T, index: number) => void;
};

export const Videos = <T extends GenericData>({ data, appointment, isEditable, handleDeleteVideo, handleVideoUpload, handleVideoVisibility }: GenericListProps<T>) => {
  const { selectedLocation } = useDealersLocations();
  const date = moment(appointment.created_on).format("YYYY/MM/DD");
  const locationPath = `${selectedLocation?.id}_${selectedLocation?.name}/${date}/${appointment.id}_${appointment.wo_nr}`;

  if (data?.length) {
    return (
      <div className="Snooze-Videos">
        {data.map((item, index) => {
          return (
            <div key={item.id} className="video-view">
              <video controls key={item.url}>
                <source src={item.url} type="video/mp4" />
                <source src={item.url} type="video/quicktime" />
              </video>
              <div className="video-controls">
                {isEditable && handleVideoVisibility && (
                  <div className="video-action-button" onClick={() => handleVideoVisibility(item, index)}>
                    <Icon className={`eye ${item.visible ? "green" : "slash red"}`} />
                  </div>
                )}
                <div className="video-actions">
                  {selectedLocation?.is_editing_question_videos_enabled && handleVideoUpload && (
                    <div className="video-action-button">
                      <UploadVideo
                        locationPath={locationPath}
                        path="/files/upload_question_result_file"
                        formId={`upload-video-${item.id}-${index}`}
                        onSuccess={url => handleVideoUpload(item, index, url)}
                      />
                    </div>
                  )}
                  <div
                    className="video-action-button"
                    onClick={() => saveAs(item.url, `Car_${appointment.reg_number_escaped}_WO_${appointment.wo_nr}_${moment().format("YYYYMMDD")}_${index + 1}.mp4`)}
                  >
                    <Icon className="download blue" />
                  </div>
                  {isEditable && handleDeleteVideo && (
                    <div className="video-action-button" onClick={() => handleDeleteVideo(item, index)}>
                      <Icon className="trash grey" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};
