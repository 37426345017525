import { Icon } from "semantic-ui-react";

interface FilePreviewProps {
  image: string;
  onDelete: () => void;
}

export const UploadFilePreview = ({ image, onDelete }: FilePreviewProps) => {
  return (
    <div className="upload-view">
      {image ? <img alt="preview image" src={image} /> : <Icon className="image i-grey" size="huge" />}
      {image && (
        <div className="delete-container" onClick={onDelete}>
          <Icon className="trash i-grey" />
        </div>
      )}
    </div>
  );
};
