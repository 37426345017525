import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";
import { FormField, FormFieldProps, Input, InputProps } from "semantic-ui-react";

type TypeFormInput<T extends FieldValues> = {
  name: Path<T>;
  inputProps?: InputProps;
  formFieldProps?: FormFieldProps;
};

export const FormInput = <T extends FieldValues>({ name, inputProps, formFieldProps }: TypeFormInput<T>) => {
  const methods = useFormContext<T>();

  return (
    <Controller
      name={name}
      {...methods}
      render={({ field, fieldState: { invalid, isTouched } }) => (
        <FormField {...formFieldProps} error={invalid && isTouched}>
          <Input
            {...methods.register(name)}
            {...field}
            {...inputProps}
            onChange={(_, { value }) => field.onChange(inputProps?.type === "number" ? parseFloat(value) : value)}
          />
        </FormField>
      )}
    />
  );
};
