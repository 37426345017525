import { createElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import { TabData } from "components";
import { useDealersLocations } from "hooks";
import { Appointment, CHECKLIST_TYPE, CarNote, Check, KeylockerRemark, KioskRemark } from "models";
import "modules/AppointmentDetails/AppointmentDetails.scss";
import { getActivityLogData, getChecklistSnoozedItems } from "modules/AppointmentDetails/components";
import { useAppointment, useChecklist } from "modules/AppointmentDetails/hooks";
import { filterAndSortStandardChecklist, filterAndSortTyreChecklist } from "modules/AppointmentDetails/utils";
import { ITranslation } from "util/interfaces";

const getAllNotes = (appointment: Appointment | undefined) => {
  if (appointment) {
    const { notes = [] } = appointment;
    const carNotes = appointment.car?.notes?.filter((note: CarNote) => note.visible_on_wo) || [];
    return [...notes, ...carNotes];
  }
  return [];
};

const getLastExtraPartQuestionResults = (checks: Check[] = []) => {
  const sortedCheckData = checks.sort((a, b) => a.created_on.localeCompare(b.created_on));
  const extraPartCheck = sortedCheckData.find((item: Check) => item.checklist.checklist_type === CHECKLIST_TYPE.ExtraPartsChecklist);
  if (!extraPartCheck || !extraPartCheck.question_items) return [];
  return extraPartCheck.question_items;
};

// TODO: cleanup like the other hooks
const useTabData = () => {
  const { selectedLocation } = useDealersLocations();
  const t = useTranslation().t as ITranslation;
  const params = useParams<{ id: string }>();
  const id = Number(params.id);

  const [tabsData, setTabsData] = useState<Map<string, TabData>>(
    new Map([
      ["details", { id: "details", icon: createElement(Icon, { className: "circle exclamation" }), data: [] }],
      ["customerActions", { id: "customerActions", icon: createElement(Icon, { className: "calendar check regular" }) }],
      ["snoozedItems", { id: "snoozedItems", icon: createElement(Icon, { className: "clock" }) }],
      ["notes", { data: [], id: "notes", icon: createElement(Icon, { className: "note sticky" }) }],
      ["interventions", { data: [], id: "interventions", icon: createElement(Icon, { className: "screwdriver wrench" }) }],
      ["extraParts", { id: "extraParts", icon: createElement(Icon, { className: "briefcase" }) }],
      ["standardList", { id: "standardList", icon: createElement(Icon, { className: "list" }) }],
      ["tireElement", { id: "tireElement", icon: createElement(Icon, { className: "tire" }) }],
      ["activityLog", { id: "activityLog", icon: createElement(Icon, { className: "user clock" }) }]
    ])
  );

  const { appointment } = useAppointment(id);

  const {
    checklistQuery: { data: checklist }
  } = useChecklist(id, true);

  const getCustomerActionsData = () => {
    const keylockerRemarks =
      appointment?.keylocker_communications?.reduce<KeylockerRemark[]>((acc, comm) => {
        if (comm.remark) acc.push(comm.remark);
        return acc;
      }, []) || [];
    const kioskRemarks =
      appointment?.kiosk_communications?.reduce<KioskRemark[]>((acc, comm) => {
        if (comm.remark) acc.push(comm.remark);
        return acc;
      }, []) || [];
    return [
      ...(appointment?.customer_communication?.check_in_results?.filter(result => result.accepted && result.visible) || []),
      ...(appointment?.customer_communication?.check_in_remarks || []),
      ...(appointment?.customer_communication?.diagnose_overview_remarks || []),
      ...keylockerRemarks,
      ...kioskRemarks
    ];
  };

  useEffect(() => {
    if (appointment || checklist) {
      setTabsData((prev: Map<string, TabData>) => {
        const tabs = new Map<string, TabData>([...prev]);
        const customerActions = getCustomerActionsData();
        const snoozedItems = getChecklistSnoozedItems(checklist);
        const notes = getAllNotes(appointment);
        const standardList = filterAndSortStandardChecklist(checklist);
        const extraParts = getLastExtraPartQuestionResults(checklist);
        const tireElement = filterAndSortTyreChecklist(checklist);
        const activityLog = getActivityLogData(appointment, t);

        if (appointment?.interventions?.length !== undefined) {
          const interventionTab = tabs.get("interventions");
          if (interventionTab) {
            interventionTab.data = appointment.interventions.length ? appointment.interventions : [];
          }
        }
        if (notes.length !== undefined) {
          const noteTab = tabs.get("notes");
          if (noteTab) {
            noteTab.data = notes.length ? notes : [];
          }
        }
        if (standardList.length !== undefined) {
          const standardTab = tabs.get("standardList");
          if (standardTab) {
            standardTab.data = standardList.length ? standardList : null;
          }
        }
        if (extraParts.length !== undefined) {
          const extraPartsTab = tabs.get("extraParts");
          if (extraPartsTab) {
            extraPartsTab.data = extraParts.length ? extraParts : null;
          }
        }
        if (snoozedItems.length !== undefined && selectedLocation?.schedule_enabled) {
          const snoozedItemsTab = tabs.get("snoozedItems");
          if (snoozedItemsTab) {
            snoozedItemsTab.data = snoozedItems.length ? snoozedItems : null;
          }
        }
        if (tireElement.length !== undefined) {
          const tireElementTab = tabs.get("tireElement");
          if (tireElementTab) {
            tireElementTab.data = tireElement.length ? tireElement : null;
          }
        }
        if (
          activityLog.length ||
          appointment?.customer_communication?.events?.length ||
          appointment?.desk_communications?.length ||
          appointment?.kiosk_communications?.length ||
          appointment?.acses_communication?.events?.length ||
          appointment?.keylocker_communications?.length
        ) {
          const activityLogTab = tabs.get("activityLog");
          if (activityLogTab) {
            activityLogTab.data = activityLog.length ? activityLog : [];
          }
        }
        if (customerActions.length !== undefined) {
          const customerActionsTab = tabs.get("customerActions");
          if (customerActionsTab) {
            customerActionsTab.data = customerActions.length ? customerActions : null;
          }
        }

        return tabs;
      });
    }
  }, [appointment, checklist, selectedLocation?.schedule_enabled]);

  return { tabsData };
};

export default useTabData;
