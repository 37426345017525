import { Icon } from "semantic-ui-react";

import "components/Notes/NoteTypeIconLabel/NoteTypeIconLabel.scss";
import { APPOINTMENT_NOTE_TYPES } from "models";

type NoteTypeIconLabelProps = {
  type: number;
  isCarNote?: boolean;
};

export const NoteTypeIconLabel = ({ type, isCarNote = false }: NoteTypeIconLabelProps) => {
  if (isCarNote)
    return (
      <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#EA2D62" }}>
        <Icon className="car mirrors carNoteIcon" />
      </div>
    );

  switch (type) {
    case APPOINTMENT_NOTE_TYPES.Info:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#2185D0" }}>
          <Icon className="info" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.BackOrder:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#6C0699" }}>
          <span style={{ color: "#FFF" }}>BO</span>
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.Wo:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#2185D0" }}>
          <Icon className="file alt" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.Main:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#FF0202" }}>
          <Icon className="sticky note" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.CallCustomer:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#21BA45" }}>
          <Icon className="phone alt" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.BillNote:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#46B046" }}>
          <Icon className="file invoice dollar" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.TireScanner:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#FF8800" }}>
          <Icon className="tile" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.TemporaryDriverNote:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#5C5C5C" }}>
          <Icon className="steering wheel" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.RecurringCar:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#C83628" }}>
          <Icon className="exclamation triangle" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.Attachment:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#21A1D0" }}>
          <Icon className="paperclip" style={{ color: "#FFF" }} />
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.Parking:
      return (
        <div className="AppointmentNoteTypeIconLabel" style={{ backgroundColor: "#21A1D0" }}>
          <Icon className="square parking" style={{ color: "#FFF" }} />
        </div>
      );
    default:
      return null;
  }
};
