import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { Appointment, Car, Customer } from "models";
import { InterventionData } from "modules/AppointmentDetails/hooks";
import apiInstance from "util/Api";

type KeyloopCar = {
  description: {
    make: string;
    model: string;
  };
  identification: {
    licensePlate: string;
    vin: string;
  };
  vehicleId: number;
};

type CustomerType = {
  key: number;
  id: string;
  title: string;
};

type KeyloopCustomer = {
  customerId: string;
  names: {
    familyName: string;
    familyName2: string;
    middleName: string;
    givenName: string;
    preferredName: string;
    initials: string;
    salutation: string;
    titleCommon: string;
  };
  relationship: string;
};

type KeyloopResponse = {
  data: { customers: KeyloopCustomer[] };
};

type KeyloopCustomerSelect = {
  correlationId: string;
  message: string;
  customerId: string;
  status: string;
  names: {
    familyName: string;
    middleName: string;
    givenName: string;
    initials: string;
    salutation: string;
  };
  personalDetails: {
    maritalStatus: string;
    languageCode: string;
    nationalityCountryCode: string;
  };
  addresses: {
    home: {
      physicalAddress: {
        streetType: string;
        streetName: string;
        houseNumber: string;
        postalCode: string;
        city: string;
        county: string;
        countryCode: string;
      };
      postalAddress: any;
    };
  };
  communication: {
    home: {
      mobile: string;
      landline: string;
      fax: string;
      email: string;
    };
    work: {
      mobile: string;
      landline: string;
      email: string;
    };
  };
};

export type KeyloopCustomerPartial = {
  firstname: string;
  surname: string;
  street: string;
  postcode: string;
  place: string;
  email_business: string;
  tel_mobile_business: string;
  tel_business_nr: string;
  dms_nr: string;
};

export interface AppointmentData extends Omit<Appointment, "interventions"> {
  interventions: InterventionData[] | null;
}

export const useNewAppointment = () => {
  const { selectedLocation, isKeyLoopLocation } = useDealersLocations();
  const [cars, setCars] = useState<Car[]>([]);
  const [customers, setCustomers] = useState<CustomerType[]>([]);
  const [keyloopCustomer, setKeyloopCustomer] = useState<KeyloopCustomerPartial | null>(null);

  const searchCarFn = (term: string) => {
    if (isKeyLoopLocation) {
      return apiInstance.post(
        "/vehicles/search",
        {
          term,
          dealer_location_id: selectedLocation?.id
        },
        ENV.keyloopBaseURL
      );
    } else {
      return apiInstance.post(
        "/cars/search/regnr",
        {
          reg_nr: term,
          location_id: selectedLocation?.id
        },
        ENV.carBaseUrl
      );
    }
  };

  const searchCar = useMutation({
    mutationFn: searchCarFn,
    onSuccess: res => {
      if (isKeyLoopLocation) {
        setCars(
          res.data?.vehicles?.map((car: KeyloopCar, index: number) => {
            return {
              key: index,
              id: index,
              title: `${car.description.make || ""} ${car.description.model || ""} - ${car.identification.licensePlate || ""}`,
              car: {
                reg_nr: car.identification.licensePlate,
                vin_nr: car.identification.vin,
                make: car.description.make,
                model: car.description.model,
                dms_nr: car.vehicleId
              }
            };
          }) ?? []
        );
      } else {
        setCars(
          res.data?.cars.map((car: Car, index: number) => {
            return {
              key: index,
              id: index,
              title: `${car.make || ""} ${car.model || ""} - ${car.reg_nr || ""}`,
              car: {
                reg_nr: car.reg_nr,
                vin_nr: car.vin_nr,
                make: car.make,
                model: car.model,
                dms_nr: car.dms_nr,
                apk_date_dms: car.apk_date_dms
              }
            };
          }) ?? []
        );
      }
    },
    onError: e => {
      toast.error(e.message);
    }
  });

  const searchCustomerFn = (term: string) => {
    return apiInstance.post("/customers/list_by_car", {
      reg_nr: term,
      dealer_location_id: selectedLocation?.id
    });
  };

  const searchCustomer = useMutation({
    mutationFn: searchCustomerFn,
    onSuccess: res =>
      setCustomers(
        res.data?.map((customer_driver: Customer, key: number) => {
          return {
            key: key,
            id: customer_driver.id,
            title: `${customer_driver.title || ""} ${customer_driver.firstname || ""} ${customer_driver.surname || ""} - ${customer_driver.place || ""}`,
            customer_driver
          };
        }) ?? []
      ),
    onError: e => {
      toast.error(e.message);
    }
  });

  const addAppointmentFn = (data: Partial<AppointmentData>) => {
    const appointment = { ...data, dealer_location_id: selectedLocation?.id };

    return apiInstance.post("/appointments/import", appointment, ENV.importerBaseUrl);
  };

  const addAppointment = useMutation({
    mutationFn: addAppointmentFn,
    onSuccess: () => toast.success("New appointment created"),
    onError: e => {
      toast.error(e.message);
    }
  });

  const searchKeyloopCustomerFn = (term: string) => {
    return apiInstance.post(
      "/vehicles/customers",
      {
        vehicle_id: term,
        dealer_location_id: selectedLocation?.id
      },
      ENV.keyloopBaseURL
    );
  };

  const handleSearchKeyloopCustomerOnSuccess = (res: KeyloopResponse) => {
    if (res?.data?.customers) {
      setCustomers(
        res.data.customers.map((customer_driver: KeyloopCustomer, key: number) => {
          return {
            key,
            id: customer_driver.customerId,
            title: `${customer_driver.names.givenName} ${customer_driver.names.familyName} - ${customer_driver.relationship.replace("_", "/")}`,
            customer_driver
          };
        })
      );
    }
  };

  const searchKeyloopCustomer = useMutation({
    mutationFn: searchKeyloopCustomerFn,
    onSuccess: handleSearchKeyloopCustomerOnSuccess,
    onError: e => {
      toast.error(e.message);
    }
  });

  const selectKeyloopCustomerFn = async (term: string) => {
    const response = await apiInstance.post(
      "/customers/view",
      {
        customer_id: term,
        dealer_location_id: selectedLocation?.id
      },
      ENV.keyloopBaseURL
    );
    return response;
  };

  const handleSelectKeyloopCustomerOnSuccess = (res: { data: KeyloopCustomerSelect }) => {
    const keyloopCustomer = res.data;

    setKeyloopCustomer({
      firstname: keyloopCustomer.names?.givenName || "",
      surname: keyloopCustomer.names?.familyName || "",
      street:
        `${keyloopCustomer.addresses?.home?.physicalAddress?.houseNumber} ${keyloopCustomer.addresses?.home?.physicalAddress?.streetType} ${keyloopCustomer.addresses?.home?.physicalAddress?.streetName}` ||
        "",
      postcode: keyloopCustomer.addresses?.home?.physicalAddress?.postalCode || "",
      place: keyloopCustomer.addresses?.home?.physicalAddress?.city || "",
      email_business: keyloopCustomer.communication?.work?.email || keyloopCustomer.communication?.home?.email || "",
      tel_mobile_business: keyloopCustomer.communication.work?.landline || keyloopCustomer.communication?.home?.landline || "",
      tel_business_nr: keyloopCustomer.communication?.work?.mobile || keyloopCustomer.communication.home?.mobile || "",
      dms_nr: keyloopCustomer.customerId || ""
    });
  };

  const selectKeyloopCustomer = useMutation({
    mutationFn: selectKeyloopCustomerFn,
    onSuccess: handleSelectKeyloopCustomerOnSuccess,
    onError: e => {
      toast.error(e.message);
    }
  });

  return { cars, customers, searchCar, searchCustomer, addAppointment, searchKeyloopCustomer, selectKeyloopCustomer, keyloopCustomer };
};
