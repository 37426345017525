import { Appointment, Car, CommunicationAgreement, Customer, KioskRemark, User } from "models";
import ApiLoadable from "models/ApiLoadable";

export enum KIOSK_COMMUNICATION_STATUS {
  KioskCheckInOpened = 1,
  KioskCheckInAnswered,
  LabelDetached
}

export enum KIOSK_COMMUNICATION_EVENT_TYPE {
  KioskCheckInOpened = 1,
  KioskCheckInAnswered,
  LabelDetached
}

export class KioskCommunicationEvent extends ApiLoadable {
  id: number = 0;
  kiosk_communication_id: number = 0;
  box_number?: number | null = null;
  correct_phone: string = "";
  pin_request_type: number = 0;
  user_id: number | null = null;
  type: KIOSK_COMMUNICATION_EVENT_TYPE = KIOSK_COMMUNICATION_EVENT_TYPE.KioskCheckInOpened;
  user?: User | null = null;
  created_on: string = "";
  updated_on: string = "";
  kiosk_label_number: number = 0;
  kiosk_name: string = "";
  customer_name: string = "";
  customer_signature: string = "";
  receivers?: KioskCommunicationReceiver[];
}

export class KioskCommunicationReceiver extends ApiLoadable {
  id: number = 0;
  communication_channel_id: number = 0;
  destination: string = "";
  status: number = 0;
  reason: string = "";
  sms_gateway_id: number | null = null;
  twilio_message_sid: number | null = null;
  mailgun_message_id: string | null = null;
  chatshipper_conversation_id: number | null = null;
  kiosk_communication_id: number = 0;
}

export interface KioskIntervention {
  id: number;
  title: string;
  description: string;
  price: number;
  vat?: number;
  kiosk_communication_id: number;
}

export class KioskCommunication extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  status: KIOSK_COMMUNICATION_STATUS = KIOSK_COMMUNICATION_STATUS.KioskCheckInOpened;
  is_checking_out: boolean = false;
  customer_id: number | null = null;
  appointment_id: number = 0;
  car_id: number = 0;
  dealer_location_id: number = 0;
  customer?: Customer;
  appointment?: Appointment;
  car?: Car;
  user?: User;
  remark?: KioskRemark;
  events?: KioskCommunicationEvent[];
  agreed_interventions?: KioskIntervention[];
  declined_interventions?: KioskIntervention[];
  agreements?: CommunicationAgreement[];
}
