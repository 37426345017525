/* eslint-disable no-empty */
import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import semver from "semver";

import { WebSocketComponent, authBroadcastChannel } from "components";
import ENV from "config/Env";
import { useUser } from "hooks";
import { Auth, LANDING_PAGE_ID, User } from "models";
import ApiLoadable from "models/ApiLoadable";
import { useRefetchAppointments, useResetAppointmentsSelectedIdentifiers } from "modules/Appointments/hooks";
import Service from "modules/Auth/service";
import ZohoDeskASAP from "plugins/ZohoDeskASAP";
import { PATHS } from "router/paths";
import ApiInstance from "util/Api";
import { getPreference, setPreference } from "util/common";
import { QUERY_KEY_COMPONENTS, queryKeys, staticLocalKey } from "util/keyFactory";

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import packageJson from "../../package.json";

type Credentials = {
  username: string;
  password: string;
};

type ResetPasswordRequest = {
  email: string;
  language_code: string;
};

type ChangePassword = {
  old_password: string;
  new_password: string;
  new_password_confirm: string;
};

type ResetPassword = {
  new_password: string;
  new_password_confirm: string;
  hash: string;
};

type GoogleLogin = {
  id_token: string;
};

type MicrosoftLogin = {
  id_token: string;
};

type UseAuthType = {
  loginWithCredentials: UseMutationResult<Auth, Error, Credentials, unknown>;
  loginWithGoogle: UseMutationResult<Auth, Error, GoogleLogin, unknown>;
  loginWithMicrosoft: UseMutationResult<Auth, Error, MicrosoftLogin, unknown>;
  resetPasswordMutation: UseMutationResult<Auth, Error, ResetPassword, unknown>;
  changePasswordMutation: UseMutationResult<Auth, Error, ChangePassword, unknown>;
  updateUserAccountMutation: UseMutationResult<any, Error, Partial<User>, unknown>;
  resetPasswordRequestMutation: UseMutationResult<Auth, Error, ResetPasswordRequest, unknown>;
  logout: (broadcastLogoutMessage?: boolean) => void;
  getNotificationElements: () => Promise<NotificationElement[]>;
  redirectToLandingPage: () => void;
  data: Auth | undefined;
  onSuccessAuth: (data: Auth, isBroadcastMessage?: boolean) => void;
};

export type NotificationElement = {
  notification_element_id: number;
  notification_entity_id: number;
  notification_field_id: number;

  entity: {
    notification_entity_id: number;
    name: string;
    template_id: number;
  };
  field: {
    notification_field_id: number;
    name: string;
    value: string;
    icon: string;
    sub_icon?: string;
  };
};

export const LandingPageMapping = new Map([
  [LANDING_PAGE_ID.Main, PATHS.APPOINTMENTS],
  [LANDING_PAGE_ID.LeadDashboard, PATHS.LEADS_DASHBOARD],
  [LANDING_PAGE_ID.WarrantyDashboard, PATHS.WARRANTY_DASHBOARD],
  [LANDING_PAGE_ID.ManufacturerDashboard, PATHS.MANUFACTURER_DASHBOARD],
  [LANDING_PAGE_ID.Dayplanner, PATHS.DAYPLANNER]
]);

export const useAuth = (): UseAuthType => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const resetStatusFilters = useResetAppointmentsSelectedIdentifiers();
  const { refetchAppointments } = useRefetchAppointments();

  const user = useUser();
  const authInfo: Auth | undefined = queryClient.getQueryData(staticLocalKey(QUERY_KEY_COMPONENTS.AuthInfo));

  const changeOldPassword = async (requestData: ChangePassword) => {
    return ApiInstance.postUnwrapped(ENV.authBaseUrl, `/password/change`, requestData, Auth);
  };

  const redirectToLandingPage = () => {
    if (!user) return history.push(PATHS.HOME);
    history.push(LandingPageMapping.get(user.landing_page_id as number));
  };

  const getNotificationElements = async (): Promise<NotificationElement[]> => {
    class NotificationElements {
      notification_elements: NotificationElement[] = [];
    }

    return (await ApiInstance.getUnwrapped(ENV.systemBaseURL, "/users/notification_elements/list", NotificationElements)).notification_elements;
  };

  const updateAccountSettings = async (requestData: Partial<User>) => {
    await ApiInstance.postUnwrapped(ENV.apiBase, `/iam/update_user`, requestData, ApiLoadable);
    return requestData;
  };

  const resetPasswordRequestFn = async (requestData: ResetPasswordRequest): Promise<Auth> => {
    return ApiInstance.postUnwrapped(ENV.authBaseUrl, `/password/forgot`, requestData, Auth);
  };

  const resetPasswordFn = async (requestData: ResetPassword): Promise<Auth> => {
    return ApiInstance.postUnwrapped(ENV.authBaseUrl, `/password/reset`, requestData, Auth);
  };

  const getCredentialsToken = async (requestData: Credentials): Promise<Auth> => {
    return ApiInstance.postUnwrapped(ENV.authBaseUrl, `/session/login/credentials`, requestData, Auth);
  };

  const getGoogleToken = async (requestData: GoogleLogin): Promise<Auth> => {
    return ApiInstance.postUnwrapped(ENV.authBaseUrl, `/session/login/google_sign_in`, requestData, Auth);
  };

  const getMicrosoftToken = async (requestData: MicrosoftLogin): Promise<Auth> => {
    return ApiInstance.postUnwrapped(ENV.authBaseUrl, `/session/login/microsoft_sign_in`, requestData, Auth);
  };

  const resetPasswordRequestMutation = useMutation({
    mutationFn: (credentials: ResetPasswordRequest) => resetPasswordRequestFn(credentials)
  });

  const onSuccessAuth = async (data: Auth, isBroadcastMessage: boolean = false) => {
    queryClient.setQueryData([queryKeys.common.isTokenExpired], false);
    queryClient.setQueryData(staticLocalKey(QUERY_KEY_COMPONENTS.AuthInfo), () => data);
    ApiInstance.authToken = { token: data.token, expire_at: data.expire_at };

    const user = await Service.getUser();

    if (user) {
      ApiInstance.zohoAsapJWT = data.zoho_asap_jwt;

      const prevUserId = getPreference("user-id");

      if (prevUserId && prevUserId !== user.id) {
        for (const key in localStorage) {
          if (key.startsWith("preferences-")) delete localStorage[key];
        }
      }

      setPreference("user-id", user.id);

      ZohoDeskASAP.login();
    }

    if (isBroadcastMessage) return window.location.reload();
    authBroadcastChannel.postMessage({ type: "LOGIN", tokenData: data });
    redirectToLandingPage();

    try {
      const {
        data: { latest_version }
      } = await Service.loadLatestVersion();
      if (latest_version && semver.gt(latest_version, packageJson.version)) document.location.reload();
    } catch (e) {
      console.warn("Error in getting latest version", e);
    }
  };

  const credentialsMutation = useMutation({
    mutationFn: (credentials: Credentials) => getCredentialsToken(credentials),
    onSuccess: res => onSuccessAuth(res)
  });

  const googleMutation = useMutation({
    mutationFn: (credentials: GoogleLogin) => getGoogleToken(credentials),
    onSuccess: res => onSuccessAuth(res)
  });

  const logout = (broadcastLogoutMessage: boolean = true) => {
    WebSocketComponent.disconnect();
    ApiInstance.clearCookies();
    queryClient.clear();
    resetStatusFilters();
    if (broadcastLogoutMessage) authBroadcastChannel.postMessage({ type: "LOGOUT" });
    history.replace(PATHS.LOGIN);
    ZohoDeskASAP.logout();
  };

  const onSuccessAccountSettings = async (userData: Partial<User>) => {
    if (user?.dealer_location_id !== userData.dealer_location_id) {
      return logout();
    }

    if (user?.list_appointments_by_scheduled_range !== userData.list_appointments_by_scheduled_range) {
      refetchAppointments();
    }

    queryClient.setQueryData(staticLocalKey(QUERY_KEY_COMPONENTS.UserData), { ...user, ...userData });
  };

  const resetPasswordMutation = useMutation({
    mutationFn: (credentials: ResetPassword) => resetPasswordFn(credentials)
  });

  const microsoftMutation = useMutation({
    mutationFn: (credentials: MicrosoftLogin) => getMicrosoftToken(credentials),
    onSuccess: res => onSuccessAuth(res)
  });

  const changePasswordMutation = useMutation({
    mutationFn: (credentials: ChangePassword) => changeOldPassword(credentials)
  });

  const updateUserAccountMutation = useMutation({
    mutationFn: (requestData: Partial<User>) => updateAccountSettings(requestData),
    onSuccess: onSuccessAccountSettings
  });

  const loginWithCredentials = credentialsMutation;

  const loginWithGoogle = googleMutation;

  const loginWithMicrosoft = microsoftMutation;

  return {
    logout,
    data: authInfo,
    resetPasswordRequestMutation,
    loginWithCredentials,
    loginWithGoogle,
    loginWithMicrosoft,
    resetPasswordMutation,
    changePasswordMutation,
    getNotificationElements,
    updateUserAccountMutation,
    redirectToLandingPage,
    onSuccessAuth
  };
};
