import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import { DropdownItemProps, Form } from "semantic-ui-react";

import { FormCheckbox, FormDropdown, FormInput, FormTextArea } from "components";
import { CommunicationSettings } from "models";
import "modules/ComunicationSettings/CustomerCom.scss";
import { ITranslation } from "util/interfaces";

interface RepairOverviewProps {
  tabId: string;
  pdfTemplateOptions: DropdownItemProps[];
}

export const RepairOverview = ({ tabId, pdfTemplateOptions }: RepairOverviewProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Element name={tabId} className="customer-com-wrapper">
      <h3>{t("v8_repair_overview").message || "REPAIR OVERVIEW"}</h3>
      <div>
        <Form.Group className="mt-16">
          <Form.Field width={3}>
            <p className="items-center">
              <span>
                <FormCheckbox<CommunicationSettings> checkboxProps={{ toggle: true }} name="repair_cc_testing_mode" />
              </span>
              {t("v8_testing_mode").message || "Testing mode"}
            </p>
          </Form.Field>
          <Form.Field width={5}>
            <label>{t("v8_email").message || "E-mail"}</label>
            <FormInput<CommunicationSettings> inputProps={{ fluid: true, placeholder: t("v8_email").message || "E-mail" }} name="repair_cc_testing_email" />
          </Form.Field>
          <Form.Field width={8}>
            <label>{t("v8_phone").message || "Phone"}</label>
            <FormInput<CommunicationSettings> inputProps={{ fluid: true, placeholder: t("v8_phone").message || "Phone", type: "Phone" }} name="repair_cc_testing_phone" />
          </Form.Field>
        </Form.Group>

        <Form.Group className="mt-14">
          <Form.Field width={8}>
            <label>{t("v8_welcome_text").message || "Welcome text"}</label>
            <FormTextArea<CommunicationSettings> textAreaProps={{ placeholder: `${t("v8_hello").message || "Hello"}!` }} name="repair_overview_welcome_text" />
          </Form.Field>

          <Form.Field width={8}>
            <label>{t("v8_pdf_template").message || "PDF template"}</label>
            <FormDropdown<CommunicationSettings>
              dropdownProps={{ options: pdfTemplateOptions, placeholder: t("v8_select_template").message || "Select template", selection: true }}
              name="report_template_id"
            />

            <FormCheckbox<CommunicationSettings>
              checkboxProps={{
                toggle: true,
                label: t("v8_automatically_send_repair_overview_on_car_ready").message || "Send repair overview automatically on car ready",
                className: "mt-10"
              }}
              name="automatically_send_repair_overview_when_car_ready"
            />

            <FormCheckbox<CommunicationSettings>
              checkboxProps={{
                toggle: true,
                label: t("v8_automatically_send_repair_overview_on_quality_check").message || "Send repair overview automatically on quality check",
                className: "mt-10"
              }}
              name="automatically_send_repair_overview_when_quality_check"
            />
          </Form.Field>
        </Form.Group>
      </div>
    </Element>
  );
};
