import { useEffect } from "react";

import { useApp, useAuth } from "hooks";

export const authBroadcastChannel = new BroadcastChannel("auth");

export const Auth = () => {
  useApp();
  const { logout, onSuccessAuth } = useAuth();

  useEffect(() => {
    const handleBroadcastMessage = (event: MessageEvent) => {
      if (event.data.type === "LOGOUT") logout(false);
      if (event.data.type === "LOGIN") onSuccessAuth(event.data.tokenData, true);
    };

    authBroadcastChannel.addEventListener("message", handleBroadcastMessage);
  }, []);

  return null;
};
